import * as api from "@/api/api";
import service from "@/utils/axios";

export const state = {};

export const getters = {};

export const mutations = {};

export const actions = {
  // eslint-disable-next-line no-unused-vars
  createToken({ commit }, data) {
    return new Promise((resolve, reject) => {
      service({
        url: api.CREATE_TOKEN_RESET_PASSWORD,
        method: "POST",
        data
      })
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  // eslint-disable-next-line no-unused-vars
  checkToken({ commit }, data) {
    return new Promise((resolve, reject) => {
      service({
        url: api.CHECK_TOKEN_PASSWORD(data.token),
        method: "GET"
      })
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  // eslint-disable-next-line no-unused-vars
  resetPassword({ commit }, data) {
    return new Promise((resolve, reject) => {
      service({
        url: api.RESET_PASSWORD,
        method: "POST",
        data
      })
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  }
};
