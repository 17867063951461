export default [
  {
    path: "/",
    name: "UserLayout",
    redirect: { name: "Home" },
    component: () => import("../layouts/UserLayout.vue"),
    children: [
      {
        path: "/new-user",
        name: "RegisterProfile",
        meta: {
          requiresVisitor: true,
          gender: 0
        },
        component: () => import("../views/RegisterProfile.vue")
      },
      {
        path: "/verify/password/reset",
        name: "CheckTokenResetPassword",
        meta: {
          requiresVisitor: false,
          gender: 0
        },
        component: () => import("../views/CheckTokenResetPassword.vue")
      },
      {
        path: "profile/:id",
        name: "CastProfile",
        meta: {
          requiresVisitor: true,
          gender: 1
        },
        component: () => import("../views/Men/CastProfile.vue")
      },
      {
        path: "/update-password",
        name: "ResetPassword",
        meta: {
          requiresVisitor: false
        },
        component: () => import("../views/Common/ResetPassword")
      },
      {
        path: "/share-app",
        name: "ShareApp",
        meta: {
          requiresVisitor: false
        },
        component: () => import("../views/ShareApp.vue")
      },
      {
        path: "/auth/facebook",
        name: "SocialFacebook",
        meta: {
          requiresVisitor: false
        },
        component: () => import("../views/LoginSocialFB.vue")
      },
      {
        path: "/auth/line",
        name: "SocialLine",
        meta: {
          requiresVisitor: false
        },
        component: () => import("../views/LoginSocialLine.vue")
      },
      {
        path: "/",
        name: "Home",
        meta: {
          requiresVisitor: true,
          gender: 1
        },
        component: () => import("../views/Men/Home.vue")
      },
      {
        path: "/profiles",
        name: "ProfileView",
        meta: {
          requiresVisitor: true,
          gender: 1
        },
        component: () => import("../views/Men/ProfileView.vue")
      },
      {
        path: "/create-order",
        name: "",
        meta: {
          requiresVisitor: true,
          gender: 1
        },
        component: () => import("../views/Men/MyPageLayout.vue"),
        children: [
          {
            path: "/",
            name: "MenCreateOrderStepOne",
            meta: {
              requiresVisitor: true,
              gender: 1
            },
            component: () => import("../views/Men/OrderStepOne.vue"),
            props: true
          },
          {
            path: "for/:id",
            name: "MenCreateOrderDirectStepOne",
            meta: {
              requiresVisitor: true,
              gender: 1
            },
            component: () => import("../views/Men/OrderStepOne.vue"),
            props: true
          },
          {
            path: "step-two",
            name: "MenCreateOrderStepTwo",
            meta: {
              requiresVisitor: true,
              gender: 1
            },
            component: () => import("../views/Men/OrderStepTwo.vue"),
            props: true
          },
          {
            path: "confirm",
            name: "MenCreateConfirmOrder",
            meta: {
              requiresVisitor: true,
              gender: 1
            },
            component: () => import("../views/Men/ConfirmOrder.vue"),
            props: true
          },
          {
            path: "order-detail/:id",
            name: "MenCreateOrderDetail",
            meta: {
              requiresVisitor: true,
              gender: 1
            },
            component: () => import("../views/Men/OrderDetail.vue"),
            props: true
          }
        ]
      },
      // {
      //   path: "/order-step-one",
      //   name: "OrderStepOne",
      //   meta: {
      //     requiresVisitor: true,
      //     gender: 1
      //   },
      //   component: () => import("../views/Men/OrderStepOne.vue"),
      //   props: true
      // },
      // {
      //   path: "/order-step-one/:id",
      //   name: "EditOrderStepOne",
      //   meta: {
      //     requiresVisitor: true,
      //     gender: 1
      //   },
      //   component: () => import("../views/Men/OrderStepOne.vue"),
      //   props: true
      // },
      // {
      //   path: "/order-step-two",
      //   name: "OrderStepTwo",
      //   meta: {
      //     requiresVisitor: true,
      //     gender: 1
      //   },
      //   component: () => import("../views/Men/OrderStepTwo.vue"),
      //   props: true
      // },
      // {
      //   path: "/order-step-two/:id",
      //   name: "EditOrderStepTwo",
      //   meta: {
      //     requiresVisitor: true,
      //     gender: 1
      //   },
      //   component: () => import("../views/Men/OrderStepTwo.vue"),
      //   props: route => ({ ...route.params })
      // },
      {
        path: "/cast-list",
        name: "CastSearch",
        meta: {
          requiresVisitor: true,
          gender: 1
        },
        component: () => import("../views/Men/CastSearch")
      },
      {
        path: "/confirm-order",
        name: "ConfirmOrder",
        meta: {
          requiresVisitor: true,
          gender: 1
        },
        component: () => import("../views/Men/ConfirmOrder.vue"),
        props: true
      },
      {
        path: "/confirm-order/:id",
        name: "EditConfirmOrder",
        meta: {
          requiresVisitor: true,
          gender: 1
        },
        component: () => import("../views/Men/ConfirmOrder.vue"),
        props: route => ({ ...route.params })
      },
      {
        path: "/confirm-order/system-message/:id",
        name: "SystemMessageConfirmOrder",
        meta: {
          requiresVisitor: true,
          gender: 1
        },
        component: () =>
          import("../views/Men/SystemMessageAfterConfirmOrder.vue")
      },
      {
        path: "/notification",
        name: "Notification",
        meta: {
          requiresVisitor: true,
          gender: 0
        },
        component: () => import("../views/Notification.vue")
      },
      {
        path: "/system-messages/:id",
        name: "MessageSystem",
        meta: {
          requiresVisitor: true,
          gender: 1
        },
        component: () => import("../views/Men/MessageSystem.vue")
      },
      // men time line
      {
        path: "/timeline",
        meta: {
          requiresVisitor: true,
          gender: 1
        },
        component: () => import("../views/Men/MyPageLayout.vue"),
        children: [
          {
            path: "",
            name: "MenTimeLine",
            meta: {
              requiresVisitor: true,
              gender: 1
            },
            component: () => import("../views/Common/Timeline/Timeline.vue")
          },
          {
            path: "list/profile",
            name: "MenTimeLineList",
            meta: {
              requiresVisitor: true,
              gender: 1
            },
            component: () =>
              import("../views/Common/Timeline/TimelineProfile.vue")
          }
        ]
      },
      // men my page
      {
        path: "/my-page",
        meta: {
          requiresVisitor: true,
          gender: 1
        },
        component: () => import("../views/Men/MyPageLayout.vue"),
        children: [
          {
            path: "",
            name: "MenMyPage",
            meta: {
              requiresVisitor: true,
              gender: 1
            },
            component: () => import("../views/Men/MyPage.vue")
          },
          {
            path: "withdrawal-pg",
            name: "MenMyPageWithdrawalPG",
            meta: {
              requiresVisitor: true,
              gender: 1
            },
            component: () => import("../views/WithdrawalPG.vue")
          },
          {
            path: "bank",
            name: "MenMyPageBankAccountRegistration",
            meta: {
              requiresVisitor: true,
              gender: 1
            },
            component: () => import("../views/BankAccountRegistration.vue")
          },
          {
            path: "/",
            name: "MenMyPage-EditProfile",
            meta: {
              requiresVisitor: true,
              gender: 1
            },
            component: () => import("../views/Men/MyPage-EditProfileLayout"),
            children: [
              {
                path: "profile",
                // name: "MenMyPageProfile",
                meta: {
                  requiresVisitor: true,
                  gender: 1
                },
                component: () =>
                  import("../views/Men/MyPage-EditProfileLayout"),
                children: [
                  {
                    path: "/",
                    name: "MenMyPageProfile",
                    meta: {
                      requiresVisitor: true,
                      gender: 1
                    },
                    component: () => import("../views/Men/MyPage-EditProfile")
                  },
                  {
                    path: "edit-profile",
                    name: "MenMyPageEditProfile",
                    meta: {
                      requiresVisitor: true,
                      gender: 1
                    },
                    component: () => import("../views/Men/EditProfile.vue")
                  },
                  {
                    path: "personal-information",
                    name: "MenMyPagePersonalInformation",
                    meta: {
                      requiresVisitor: true,
                      gender: 1
                    },
                    component: () => import("../views/PersonalInformation.vue")
                  },
                  {
                    path: "personal-information/edit",
                    name: "MenMyPagePersonalInformationEdit",
                    meta: {
                      requiresVisitor: true,
                      gender: 1
                    },
                    component: () => import("../views/PersonalInformation.vue")
                  },
                  {
                    path: "identify-profile",
                    name: "MenMyPageIdentifyProfile",
                    meta: {
                      requiresVisitor: true,
                      gender: 1
                    },
                    component: () => import("../views/IdentifyProfile.vue")
                  },
                  {
                    path: "identify-profile/step2",
                    name: "MenMyPageIdentifyProfileStepTwo",
                    meta: {
                      requiresVisitor: true,
                      gender: 1
                    },
                    component: () =>
                      import("../views/Common/IdentifyProfileStepTwo.vue")
                  },
                  {
                    path: "identify-profile/step3",
                    name: "MenMyPageIdentifyProfileStepThree",
                    meta: {
                      requiresVisitor: true,
                      gender: 1
                    },
                    component: () =>
                      import("../views/Common/IdentifyProfileStepthree.vue")
                  }
                ]
              },
              {
                path: "points/buy",
                name: "BuyPoint",
                meta: {
                  requiresVisitor: true,
                  gender: 1
                },
                component: () => import("../views/Men/BuyPoint.vue")
              },
              {
                path: "point-history",
                name: "PointHistory",
                meta: {
                  requiresVisitor: true,
                  gender: 1
                },
                component: () => import("../views/Men/pointHistory.vue")
              },
              {
                path: "edit/profiles",
                name: "EditProfile",
                meta: {
                  requiresVisitor: true,
                  gender: 1
                },
                component: () => import("../views/Men/EditProfile.vue")
              }
            ]
          },
          {
            path: "/",
            name: "",
            meta: {
              requiresVisitor: true,
              gender: 1
            },
            component: () => import("../views/Men/HelpLayout.vue"),
            children: [
              {
                path: "help",
                name: "MenMyPageHelp",
                meta: {
                  requiresVisitor: true,
                  gender: 1
                },
                component: () => import("../views/Help.vue")
              },
              {
                path: "law",
                name: "MenLaw",
                meta: {
                  requiresVisitor: true,
                  gender: 1
                },
                component: () => import("../views/Law.vue")
              },
              {
                path: "policy",
                name: "MenPolicy",
                meta: {
                  requiresVisitor: true,
                  gender: 1
                },
                component: () => import("../views/Common/Policy.vue")
              },
              {
                path: "tutorial/law",
                name: "MenTutorialLaw",
                meta: {
                  requiresVisitor: true,
                  gender: 1
                },
                component: () => import("../views/Common/Law.vue")
              },
              {
                path: "change-password",
                name: "MenChangePassword",
                meta: {
                  requiresVisitor: true,
                  gender: 1
                },
                component: () => import("../views/ChangePassword.vue")
              },
              {
                path: "faq",
                name: "MenFAQ",
                meta: {
                  requiresVisitor: true,
                  gender: 1
                },
                component: () => import("../views/FAQ.vue")
              },
              {
                path: "term-of-use",
                name: "MenTermOfUse",
                meta: {
                  requiresVisitor: true,
                  gender: 1
                },
                component: () => import("../views/TermOfUse.vue")
              },
              {
                path: "register-card-credit",
                name: "MenRegisterCardCredit",
                meta: {
                  requiresVisitor: true,
                  gender: 1
                },
                component: () => import("../views/Men/RegisterCardCredit.vue"),
                props: true
              },
              {
                path: "info-card-credit",
                name: "InfoCardCredit",
                meta: {
                  requiresVisitor: true,
                  gender: 1
                },
                component: () => import("../views/Men/InfoCardCredit.vue")
              }
            ]
          },
          {
            path: "blocked",
            name: "MenMyPageBlock",
            meta: {
              requiresVisitor: true,
              gender: 1
            },
            component: () => import("../views/Block.vue")
          },
          {
            path: "tracks",
            name: "MenMyPageTracks",
            meta: {
              requiresVisitor: true,
              gender: 1
            },
            component: () => import("../views/Tracks.vue")
          },
          {
            path: "ranking",
            name: "MenMyPageRanking",
            meta: {
              requiresVisitor: true,
              gender: 1
            },
            component: () => import("../views/Ranking.vue")
          },
          {
            path: "setting-notification",
            name: "MenMyPageSettingNotification",
            meta: {
              requiresVisitor: true,
              gender: 1
            },
            component: () => import("../views/Men/SettingNotification.vue")
          },
          {
            path: "setting",
            name: "MenSetting",
            meta: {
              requiresVisitor: true,
              gender: 1
            },
            component: () => import("../views/Men/Setting")
          },
          {
            path: "setting-account",
            name: "MenSettingAccount",
            meta: {
              requiresVisitor: true,
              gender: 1
            },
            component: () => import("../views/Men/SettingAccount")
          },
          {
            path: "package-info",
            name: "MenPackageInfo",
            meta: {
              requiresVisitor: true,
              gender: 1
            },
            component: () => import("../views/Men/PackageInfo")
          },
          {
            path: "orders",
            name: "",
            meta: {
              requiresVisitor: true,
              gender: 1
            },
            component: () => import("../components/LayoutSimple.vue"),
            props: true,
            children: [
              {
                path: "/",
                name: "MenOrderList",
                meta: {
                  requiresVisitor: true,
                  gender: 1
                },
                component: () => import("../views/Men/OrderList.vue"),
                props: true
              },
              {
                path: "edit/:id",
                name: "MenEditOrderStepOne",
                meta: {
                  requiresVisitor: true,
                  gender: 1
                },
                component: () => import("../views/Men/OrderStepOne.vue"),
                props: true
              },
              {
                path: "edit-policy/:id",
                name: "MenEditOrderStepTwo",
                meta: {
                  requiresVisitor: true,
                  gender: 1
                },
                component: () => import("../views/Men/OrderStepTwo.vue"),
                props: route => ({ ...route.params })
              },
              {
                path: "edit-confirm/:id",
                name: "MenEditConfirmOrder",
                meta: {
                  requiresVisitor: true,
                  gender: 1
                },
                component: () => import("../views/Men/ConfirmOrder.vue"),
                props: route => ({ ...route.params })
              }
            ]
          },
          {
            path: "withdrawal",
            name: "MenMyPageWithdrawal",
            meta: {
              requiresVisitor: true,
              gender: 1
            },
            component: () => import("../views/Withdrawal.vue")
          },
          {
            path: "bank",
            name: "MenBankAccountRegistration",
            meta: {
              requiresVisitor: true,
              gender: 1
            },
            component: () => import("../views/BankAccountRegistration.vue")
          },
          {
            path: "my-profile",
            name: "ProfileViewMyProfile",
            meta: {
              requiresVisitor: true,
              gender: 1
            },
            component: () => import("../views/Men/ProfileView.vue")
          },
          {
            path: "roundlog/list",
            name: "MenRoundLogList",
            meta: {
              requiresVisitor: true,
              gender: 1
            },
            component: () => import("../views/RoundLogList.vue")
          },
          {
            path: "/roundlog/survey/:userId/:adId",
            name: "Survey",
            meta: {
              requiresVisitor: true,
              gender: 1
            },
            component: () => import("../views/Common/Roundlog/survey.vue")
          }
        ]
      },
      {
        path: "/tracks",
        name: "Tracks",
        meta: {
          requiresVisitor: true,
          gender: 0
        },
        component: () => import("../views/Tracks.vue")
      },
      // {
      //   path: "/history-receiption",
      //   name: "PointHistoryReceiption",
      //   meta: {
      //     requiresVisitor: true,
      //     gender: 1
      //   },
      //   component: () => import("../views/Men/PointHistoryReceiption.vue")
      // },
      {
        path: "/my-page/point-history/points-details/:id",
        name: "DetailPoint",
        meta: {
          requiresVisitor: true,
          gender: 1
        },
        component: () => import("../views/Men/DetailPoint.vue")
      },
      {
        path: "/bank",
        name: "MenBankAccountRegistration",
        meta: {
          requiresVisitor: true,
          gender: 1
        },
        component: () => import("../views/BankAccountRegistration.vue")
      },
      {
        path: "/order-cast",
        name: "OrderCast",
        meta: {
          requiresVisitor: true,
          gender: 1
        },
        component: () => import("../views/Men/OrderCast.vue"),
        props: true
      }
    ]
  },
  {
    path: "/cast",
    name: "",
    redirect: { name: "Home" },
    component: () => import("../layouts/UserLayout.vue"),
    children: [
      {
        path: "profile/:id",
        name: "MenProfile",
        meta: {
          requiresVisitor: true,
          gender: 2
        },
        component: () => import("../views/Cast/MenProfile.vue")
      },
      {
        path: "/",
        name: "CastHome",
        meta: {
          requiresVisitor: true,
          gender: 2
        },
        component: () => import("../views/Cast/Home.vue")
      },
      {
        path: "/cast/roundlog/list",
        name: "CastRoundLogList",
        meta: {
          requiresVisitor: true,
          gender: 2
        },
        component: () => import("../views/RoundLogList.vue")
      },
      {
        path: "/cast/search-men",
        name: "SearchMen",
        meta: {
          requiresVisitor: true,
          gender: 2
        },
        component: () => import("../views/Cast/SearchMen.vue")
      },
      // cast my page
      {
        path: "/cast/my-page",
        name: "",
        meta: {
          requiresVisitor: true,
          gender: 2
        },
        component: () => import("../views/Cast/MyPageLayout.vue"),
        children: [
          {
            path: "/cast/roundlog/list",
            name: "CastRoundLogList",
            meta: {
              requiresVisitor: true,
              gender: 2
            },
            component: () => import("../views/RoundLogList.vue")
          },
          {
            path: "/cast/roundlog/survey/:userId/:adId",
            name: "CastSurvey",
            meta: {
              requiresVisitor: true,
              gender: 2
            },
            component: () => import("../views/Common/Roundlog/surveyCast.vue")
          },
          {
            path: "bank",
            name: "CastMyPageBankAccountRegistration",
            meta: {
              requiresVisitor: true,
              gender: 2
            },
            component: () => import("../views/BankAccountRegistration.vue")
          },
          {
            path: "/",
            name: "CastMyPage",
            meta: {
              requiresVisitor: true,
              gender: 2
            },
            component: () => import("../views/Cast/MyPage.vue")
          },
          {
            path: "point-history",
            name: "CastPointHistory",
            meta: {
              requiresVisitor: true,
              gender: 2
            },
            component: () => import("../views/Cast/PointHistory.vue")
          },
          {
            path: "bd-history",
            name: "CastMyPageBdHistory",
            meta: {
              requiresVisitor: true,
              gender: 2
            },
            component: () => import("../views/Cast/BdHistory.vue")
          },
          {
            path: "profile",
            name: "CastMyPageProfile",
            meta: {
              requiresVisitor: true,
              gender: 2
            },
            component: () => import("../views/Cast/ProfileView.vue")
          },
          {
            path: "/",
            name: "CastMyPage-EditProfile",
            meta: {
              requiresVisitor: true,
              gender: 2
            },
            component: () => import("../views/Cast/MyPage-EditProfileLayout"),
            children: [
              {
                path: "edit/profile",
                name: "CastMyPageEditProfile",
                meta: {
                  requiresVisitor: true,
                  gender: 2
                },
                component: () => import("../views/Cast/MyPage-EditProfile")
              },

              {
                path: "edit-profile",
                name: "EditProfileCast",
                meta: {
                  requiresVisitor: true,
                  gender: 2
                },
                component: () => import("../views/Cast/EditProfile.vue")
              },
              {
                path: "point-history",
                name: "CastMyPagePointHistory",
                meta: {
                  requiresVisitor: true,
                  gender: 2
                },
                component: () => import("../views/Cast/PointHistory.vue")
              },
              {
                path: "edit/profiles",
                name: "CastEditProfile",
                meta: {
                  requiresVisitor: true,
                  gender: 2
                },
                component: () => import("../views/Men/EditProfile.vue")
              },
              {
                path: "personal/information",
                name: "CastMyPagePersonalInformation",
                meta: {
                  requiresVisitor: true,
                  gender: 2
                },
                component: () => import("../views/PersonalInformation.vue")
              },
              {
                path: "personal/information/edit",
                name: "CastMyPagePersonalInformationEdit",
                meta: {
                  requiresVisitor: true,
                  gender: 2
                },
                component: () => import("../views/PersonalInformation.vue")
              },
              {
                path: "identify-profile",
                name: "CastMyPageIdentifyProfile",
                meta: {
                  requiresVisitor: true,
                  gender: 2
                },
                component: () => import("../views/IdentifyProfile.vue")
              },
              {
                path: "identify-profile/step2",
                name: "CastMyPageIdentifyProfileStepTwo",
                meta: {
                  requiresVisitor: true,
                  gender: 2
                },
                component: () =>
                  import("../views/Common/IdentifyProfileStepTwo.vue")
              },
              {
                path: "identify-profile/step3",
                name: "CastMyPageIdentifyProfileStepThree",
                meta: {
                  requiresVisitor: true,
                  gender: 2
                },
                component: () =>
                  import("../views/Common/IdentifyProfileStepthree.vue")
              }
            ]
          },
          {
            path: "setting-notification",
            name: "settingNotificationCast",
            meta: {
              requiresVisitor: true,
              gender: 2
            },
            component: () => import("../views/Cast/SettingNotification")
          },
          {
            path: "/",
            name: "",
            meta: {
              requiresVisitor: true,
              gender: 2
            },
            component: () => import("../views/Cast/HelpLayout.vue"),
            children: [
              {
                path: "help",
                name: "CastMyPageHelp",
                meta: {
                  requiresVisitor: true,
                  gender: 2
                },
                component: () => import("../views/Help.vue")
              },
              {
                path: "law",
                name: "CastLaw",
                meta: {
                  requiresVisitor: true,
                  gender: 2
                },
                component: () => import("../views/Law.vue")
              },
              {
                path: "policy",
                name: "CastPolicy",
                meta: {
                  requiresVisitor: true,
                  gender: 2
                },
                component: () => import("../views/Common/Policy.vue")
              },
              {
                path: "tutorial/law",
                name: "CastTutorialLaw",
                meta: {
                  requiresVisitor: true,
                  gender: 2
                },
                component: () => import("../views/Common/Law.vue")
              },
              {
                path: "change-password",
                name: "CastChangePassword",
                meta: {
                  requiresVisitor: true,
                  gender: 2
                },
                component: () => import("../views/ChangePassword.vue")
              },
              {
                path: "faq",
                name: "CastFAQ",
                meta: {
                  requiresVisitor: true,
                  gender: 2
                },
                component: () => import("../views/FAQ.vue")
              },
              {
                path: "term-of-use",
                name: "CastTermOfUse",
                meta: {
                  requiresVisitor: true,
                  gender: 2
                },
                component: () => import("../views/TermOfUse.vue")
              },
              {
                path: "register-card-credit",
                name: "CastRegisterCardCredit",
                meta: {
                  requiresVisitor: true,
                  gender: 2
                },

                component: () => import("../views/Men/RegisterCardCredit.vue")
              }
            ]
          },
          {
            path: "blocked",
            name: "CastMyPageBlock",
            meta: {
              requiresVisitor: true,
              gender: 2
            },
            component: () => import("../views/Block.vue")
          },
          {
            path: "tracks",
            name: "CastMyPageTracks",
            meta: {
              requiresVisitor: true,
              gender: 2
            },
            component: () => import("../views/Tracks.vue")
          },
          {
            path: "ranking",
            name: "Ranking",
            meta: {
              requiresVisitor: true,
              gender: 2
            },
            component: () => import("../views/Ranking.vue")
          },
          {
            path: "withdrawal",
            name: "CastMyPageWithdrawal",
            meta: {
              requiresVisitor: true,
              gender: 2
            },
            component: () => import("../views/Withdrawal.vue")
          },
          {
            path: "withdrawal-pg",
            name: "CastMyPageWithdrawalPG",
            meta: {
              requiresVisitor: true,
              gender: 2
            },
            component: () => import("../views/WithdrawalPG.vue")
          },
          {
            path: "bank",
            name: "CastBankAccountRegistration",
            meta: {
              requiresVisitor: true,
              gender: 2
            },
            component: () => import("../views/BankAccountRegistration.vue")
          },
          {
            path: "round-log",
            name: "CastRoundLogList",
            meta: {
              requiresVisitor: true,
              gender: 2
            },
            component: () => import("../views/RoundLogList.vue")
          }
        ]
      },
      // {
      //   path: "/cast/menu-edit",
      //   name: "MenuEdit",
      //   meta: {
      //     requiresVisitor: true,
      //     gender: 2
      //   },
      //   component: () => import("../views/Cast/MenuEditList.vue")
      // },
      {
        path: "/cast/profiles",
        name: "ProfileViewCast",
        meta: {
          requiresVisitor: true,
          gender: 2
        },
        component: () => import("../views/Cast/ProfileView.vue")
      },
      {
        path: "/cast/register",
        name: "CastRegister",
        meta: {
          requiresVisitor: true
        },
        component: () => import("../views/Cast/Register.vue")
      },
      {
        path: "/reset-password",
        name: "ResetPasswordByEmail",
        meta: {
          requiresVisitor: false,
          gender: 0
        },
        component: () => import("../views/Common/ResetPasswordByEmail")
      },
      {
        path: "/cast/orders",
        name: "CastOfferList",
        meta: {
          requiresVisitor: true,
          gender: 2
        },
        component: () => import("../views/Cast/OfferList.vue")
      },
      // cast time line
      {
        path: "/cast/timeline",
        meta: {
          requiresVisitor: true,
          gender: 2
        },
        component: () => import("../views/Cast/MyPageLayout.vue"),
        children: [
          {
            path: "",
            name: "CastTimeLine",
            meta: {
              requiresVisitor: true,
              gender: 2
            },
            component: () => import("../views/Common/Timeline/Timeline.vue")
          },
          {
            path: "list/profile",
            name: "CastTimeLineList",
            meta: {
              requiresVisitor: true,
              gender: 2
            },
            component: () =>
              import("../views/Common/Timeline/TimelineProfile.vue")
          }
        ]
      }
    ]
  },
  {
    path: "/export",
    name: "PointPdfExport",
    meta: {
      requiresVisitor: false,
      gender: 0
    },
    component: () => import("../views/PointPdfExport.vue")
  },
  {
    path: "/messages",
    name: "Messages",
    meta: {
      requiresVisitor: true,
      gender: 0
    },
    component: () =>
      window.innerWidth < 1080
        ? import("../views/Men/MessageList.vue")
        : import("../views/Men/MessagePC.vue")
  },
  {
    path: "/messages/:id",
    name: "MessageDetail",
    meta: {
      requiresVisitor: true,
      gender: 0
    },
    component: () =>
      window.innerWidth < 1080
        ? import("../views/Men/MessageDetail.vue")
        : import("../views/Men/MessagePC.vue")
  },
  {
    path: "/favorites",
    name: "Favorite",
    meta: {
      requiresVisitor: true,
      gender: 0
    },
    component: () => import("../views/Favorite.vue")
  },
  {
    path: "/register",
    name: "Register",
    meta: {
      requiresVisitor: false,
      gender: 2
    },
    component: () => import("../views/Register.vue")
  },
  {
    path: "/email-register",
    name: "EmailRegister",
    meta: {
      requiresVisitor: false,
      gender: 2
    },
    component: () => import("../views/EmailRegister.vue")
  },
  {
    path: "/verify/email/register",
    name: "Verify",
    meta: {
      requiresVisitor: false,
      gender: 2
    },
    component: () => import("../views/VerifyRegisterEmail.vue")
  },
  {
    path: "/login",
    name: "LoginEmail",
    meta: {
      requiresVisitor: false,
      gender: 2
    },
    component: () => import("../views/LoginEmail.vue")
  },
  {
    path: "/fobidden",
    name: "403",
    component: () => import("../components/Fobidden.vue")
  },
  {
    path: "/no-account",
    name: "NoAccount",
    component: () => import("../components/NoAccount.vue")
  },
  {
    path: "*",
    name: "404",
    component: () => import("../components/PageNotFound.vue")
  },
  {
    path: "",
    name: "TopRegister",
    meta: {
      requiresVisitor: false,
      gender: 2
    },
    component: () => import("../views/Cast/FirstTimeRegister.vue")
  }
];
