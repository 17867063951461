import Vue from "vue";
import App from "./App.vue";
import { BootstrapVue, BootstrapVueIcons, BPagination } from "bootstrap-vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
import plugins from "./plugins";
import { extend, localize } from "vee-validate";
import ja from "vee-validate/dist/locale/ja.json";
import * as rules from "vee-validate/dist/rules";
import Meta from "vue-meta";
import { loadVeeValidateExtend } from "./plugins/vee-validate";
import ScrollLoader from "vue-scroll-loader";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import VueClipboard from "vue-clipboard2";
import VueTheMask from "vue-the-mask";
import firebase from "./firebase";
import components from "./commons/component";
import VueCarousel from "vue-carousel";
import VueMoJS from "vue-mo-js";

Object.keys(rules).forEach(rule => {
  extend(rule, rules[rule]);
});

localize("ja", ja);
Vue.use(components);
Vue.use(BootstrapVue);
Vue.use(BootstrapVueIcons);
Vue.use(plugins);
Vue.use(Meta);
Vue.use(ScrollLoader);
Vue.use(DatePicker);
Vue.use(VueTheMask);
Vue.config.productionTip = false;
loadVeeValidateExtend();
Vue.use(VueClipboard);
Vue.use(VueCarousel);
Vue.use(VueMoJS);
// Vue.directive("close-popover", VClosePopover);
// Vue.component("v-popover", VPopover);
Vue.component("b-pagination", BPagination);
Vue.prototype.$messaging = firebase.messaging;

router.afterEach(async () => {
  await firebase.analytics.logEvent("page_view");
});

new Vue({
  router,
  store,
  ...App
}).$mount("#app");
