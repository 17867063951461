export const CREATE_ORDER_API = user_id => `/api/v1/users/${user_id}/ads`;
export const EDIT_ORDER_API = (user_id, ad_id) =>
  `/api/v1/users/${user_id}/ads/${ad_id}/update`;
export const CANCEL_ORDER_API = (user_id, ad_id) =>
  `/api/v1/users/${user_id}/ads/${ad_id}/cancel`;
export const DELAY_ORDER_API = `/api/v1/users/delay-confirm`;
export const REPORT_ORDER_API = `/api/v1/users/report-order`;
export const COMPLAIN_REPORT_ORDER_API = `/api/v1/users/complain-report-order`;
export const CONTACT_REPORT_ORDER_API = `/api/v1/users/contact-report-order`;
export const GET_USERS_REPORT_ORDER_API = `/api/v1/users/get-user-report`;
export const DELAY_ORDER_WOMEN_API = `/api/v1/users/delay-confirm-women`;
export const ORDER_LIST_API = user_id => `/api/v1/users/${user_id}/ads`;
export const ORDER_DETAIL_API = ad_id => `/api/v1/ads/${ad_id}`;
export const CAST_LIST_API = ad_id => `/api/v1/ads/${ad_id}/applicants`;
export const ORDER_CAST_API = (user_id, ad_id) =>
  `/api/v1/users/${user_id}/ads/${ad_id}/confirm`;
export const DESIGNATE_ORDER_API = user_id =>
  `/api/v1/ads/designations/${user_id}`;

export const TEST_API = `/api/v1/export`;
