import * as api from "@/api/api";
import service from "@/utils/axios";

export const state = {
  commonTutorials: [],
  isLoading: true,
  listArea: []
};

export const getters = {
  commonTutorials: state => state.commonTutorials,
  isLoading: state => state.isLoading
};

export const mutations = {
  getCommonTutorials(state, common) {
    state.commonTutorials = common;
  },
  SET_IS_LOADING(state, payload) {
    state.isLoading = payload;
  },
  getListArea(state, data) {
    state.listArea = data;
  }
};

export const actions = {
  async commonsTutorials({ commit }, payload) {
    await service({
      url: api.COMMON_TUTORIALS,
      method: "GET",
      params: {
        array_common_request: payload.array_common_request
      }
    }).then(response => {
      commit("getCommonTutorials", response.data);
    });
  },
  setIsLoading({ commit }, status) {
    commit("SET_IS_LOADING", status);
  },

  getListArea(_, params) {
    return new Promise((resolve, reject) => {
      service({
        url: api.LIST_AREA,
        method: "GET",
        params
      })
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  }
};
