import firebase from "firebase";
import "firebase/firebase-messaging";

const firebaseConfig = {
  apiKey: process.env.VUE_APP_FIREBASE_API_KEY,
  authDomain: process.env.VUE_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.VUE_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.VUE_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.VUE_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.VUE_APP_FIREBASE_APP_ID,
  measurementId: process.env.VUE_APP_FIREBASE_MEASUREMENT_ID
};

const firebaseApp = firebase.initializeApp(firebaseConfig);
const analytics = firebase.analytics();

const db = firebaseApp.firestore();
const storage = firebaseApp.storage();
let messaging = null;

const userAgentString = navigator.userAgent;
const chromeAgent = userAgentString.indexOf("Chrome") > -1;
let isSafariBrowser = userAgentString.indexOf("Safari") > -1;
if (chromeAgent && isSafariBrowser) {
  isSafariBrowser = false;
}
if (!isSafariBrowser) {
  messaging = firebaseApp.messaging();
  // Handle incoming messages. Called when:
  // - a message is received while the app has focus
  // - the user clicks on an app notification created by a service worker
  //   `messaging.onBackgroundMessage` handler.
  messaging.onMessage(payload => {
    console.log("Message received. ", payload);
    // ...
  });
}

export default { db, messaging, storage, analytics };
