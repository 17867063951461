import * as api from "@/api/api";
import service from "@/utils/axios";

export const state = {};

export const getters = {};

export const mutations = {};

export const actions = {
  // eslint-disable-next-line no-unused-vars
  updatePassword({ commit }, data) {
    return new Promise((resolve, reject) => {
      service({
        url: api.UPDATE_PASSWORD,
        method: "PUT",
        data
      })
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  }
};
