import service from "@/utils/axios";
import { ADS_API_URL, COMMON_TUTORIALS } from "@/api/api";

export const state = {
  List: [],
  Areas: [],
  Plans: [],
  Ranks: [],
  Exps: []
};

export const getters = {
  List: state => state.List,
  Areas: state => state.Areas,
  Ranks: state => state.Ranks,
  Plans: state => state.Plans,
  Exps: state => state.Exps
};

export const mutations = {
  SET_OFFER_LIST(state, payload) {
    state.List = [...state.List, ...payload];
  },

  SET_OFFER_LIST_RESET(state, payload) {
    state.List = payload;
  },

  SET_AREA(state, payload) {
    state.Areas = payload;
  },

  SET_PLAN(state, payload) {
    state.Plans = payload;
  },

  SET_RANK(state, payload) {
    state.Ranks = payload;
  },

  SET_EXPS(state, payload) {
    state.Exps = payload;
  },

  UPDATE_OFFER(state, payload) {
    if (payload.type === "cancel") {
      // cancel offer
      state.List = state.List.map(item => {
        if (item.ad_id === payload.offerId) {
          item.canceled_at = payload.data.canceled_at;
        }
      });
    } else if (payload.type === "apply") {
      // apply offer
      state.List = state.List.map(item => {
        if (item.ad_id === payload.offerId) {
          // code
        }
      });
    }
  }
};

export const actions = {
  // get info area, plan, rank for search popup
  getOfferDetail({ commit }) {
    return new Promise((resolve, reject) => {
      service({
        url: COMMON_TUTORIALS,
        method: "GET",
        params: {
          array_common_request: [
            "plans",
            "golfAreas",
            "golfExperiences",
            "femaleGolfRanks"
          ]
        }
      })
        .then(response => {
          // get list area
          let areas = [];
          response?.data.golfAreas.forEach(item => {
            areas.push({
              id: item.golf_area_id,
              title: item.name
            });
          });
          commit("SET_AREA", areas);

          // get list epxs
          let exps = [];
          response?.data.golfExperiences.forEach(item => {
            exps.push({
              id: item.golf_experience_id,
              title: item.name
            });
          });
          commit("SET_EXPS", exps);

          // get list plan
          let plans = [];
          response?.data.plans.forEach(item => {
            plans.push({
              id: item.plan_id,
              title: item.name
            });
          });
          commit("SET_PLAN", plans);

          // get list ranks
          let ranks = [];
          response?.data.femaleGolfRanks.forEach(item => {
            ranks.push({
              id: item.female_golf_rank_id,
              title: item.name
            });
          });
          commit("SET_RANK", ranks);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  // get list order
  async getList({ commit }, params) {
    let commitType = params.commit_type; // SET_OFFER_LIST; SET_OFFER_LIST_RESET
    delete params.commit_type;
    return new Promise((resolve, reject) => {
      service({
        url: ADS_API_URL,
        method: "GET",
        params
      })
        .then(response => {
          resolve(response);
          commit(commitType, response.data.ads);
        })
        .catch(error => {
          reject(error);
        });
    });
  }
};
