import * as api from "@/api/gift";
import service from "@/utils/axios";

export const state = {
  gifts: [],
  giftAvailables: []
};
export const getters = {
  gifts: state => state.gifts,
  giftAvailables: state => state.giftAvailables
};

export const mutations = {
  getGift(state, data) {
    state.gifts = data;
  },
  getGiftAvailables(state, data) {
    state.giftAvailables = data;
  }
};

export const actions = {
  getGifts({ commit }) {
    return new Promise((resolve, reject) => {
      service({
        url: api.GET_GIFTS_API,
        method: "GET"
      })
        .then(response => {
          resolve(response);
          commit("getGift", response.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  getGiftAvailables({ commit }) {
    return new Promise((resolve, reject) => {
      service({
        url: api.GET_GIFTS_TIPPING_API,
        method: "GET"
      })
        .then(response => {
          resolve(response);
          commit("getGiftAvailables", response.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  purchaseGift(_, data) {
    return new Promise((resolve, reject) => {
      service({
        url: api.PURCHASE_GIFT_API(data.user_id),
        method: "POST",
        data
      })
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  tip(_, data) {
    return new Promise((resolve, reject) => {
      service({
        url: api.TIP_API(data.user_id, data.user_gift_id),
        method: "PUT",
        data
      })
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  }
};
