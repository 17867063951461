import * as api from "@/api/order";
import service from "@/utils/axios";
import dayjs from "dayjs";
import { STATUS_ORDER } from "@/utils/const";

const initOrder = {
  scheduled_meeting_datetime: null,
  male_participants: 0,
  golf_area_id: "",
  female_participants: 0,
  plan_id: "",
  golf_course_id: "",
  golf_course_new: "",
  female_golf_ranks: [],
  female_prego_ranks: [],
  reservation_status: 1,
  attitude_ids: [],
  preferred_age_ids: [],
  appearance_ids: [],
  figure_ids: [],
  other_preference_ids: [],
  single_participant: 0,
  request: "",
  // deadline_date: "2022-01-01", // TODO.
  isSave: false,
  isEdit: false,
  profileId: null,
  designation_user_id: null
};

export const state = {
  ads: [],
  castList: {
    applicants: [],
    designations: []
  },
  searchOrder: {
    ...initOrder
  }
};
export const getters = {
  ads: state => state.ads,
  searchOrder: state => state.searchOrder,
  castList: state => state.castList
};

export const mutations = {
  getOrderList(state, data) {
    const newData =
      (data?.ads &&
        data.ads.map(el => {
          return {
            ...el,
            isConfirm: false,
            isExpand: false
          };
        })) ||
      [];
    state.ads = [...state.ads, ...newData];
  },
  getOrderListNew(state, data) {
    const newData =
      (data?.ads &&
        data.ads.map(el => {
          return {
            ...el,
            isConfirm: false,
            isExpand: false
          };
        })) ||
      [];
    state.ads = [...newData];
  },
  getCastList(state, data) {
    state.castList = {
      applicants: (data.applicants || [])
        .filter(el => el.status !== STATUS_ORDER.CANCELED)
        .map(el => {
          return {
            ...el,
            isSelected: false
          };
        }),
      designations: (data.designations || []).map(el => {
        return {
          ...el,
          isSelected: false
        };
      })
    };
  },
  getOrderDetail(state, ad) {
    state.searchOrder = {
      ...initOrder,
      scheduled_meeting_datetime: dayjs(ad.scheduled_meeting_datetime).$d,
      male_participants: ad.male_participants,
      designation_user_id: ad.designation_user_id,
      golf_area_id: ad.golf_area_id,
      female_participants: ad.female_participants,
      plan_id: ad.plan_id,
      golf_course_id: ad.golf_course_id,
      golf_course_new: ad.golf_course_id ? "" : ad.golf_course,
      female_golf_ranks: ad.female_golf_rank_ids
        .split(",")
        .map(el => Number(el)),
      female_prego_ranks: ad.female_prego_rank_ids
        .split(",")
        .map(el => Number(el)),
      reservation_status: ad.reservation_status_ads,
      attitude_ids: ad.attitude_ids
        ? ad.attitude_ids.split(",").map(el => Number(el))
        : [],
      preferred_age_ids: ad.preferred_age_ids
        ? ad.preferred_age_ids.split(",").map(el => Number(el))
        : [],
      appearance_ids: ad.appearance_ids
        ? ad.appearance_ids.split(",").map(el => Number(el))
        : [],
      figure_ids: ad.figure_ids
        ? ad.figure_ids.split(",").map(el => Number(el))
        : [],
      other_preference_ids: ad.other_preference_ids
        ? ad.other_preference_ids.split(",").map(el => Number(el))
        : [],
      single_participant: ad.single_participant,
      request: ad.request,
      isEdit: true
    };
  },
  resetOrder(state) {
    state.searchOrder = {
      ...initOrder
    };
  },
  saveOrder(state) {
    state.searchOrder = {
      ...state.searchOrder,
      isSave: true
    };
  }
};

export const actions = {
  export(_, params) {
    return new Promise((resolve, reject) => {
      service({
        url: api.TEST_API,
        method: "GET",
        responseType: "arraybuffer",
        params: {
          data: params
        }
      })
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  getOrderList({ commit }, params) {
    return new Promise((resolve, reject) => {
      let request = {
        page: params.page,
        perpage: params.perpage
      };
      if (params.profile_id) {
        request.profile_id = params.profile_id;
      }
      service({
        url: api.ORDER_LIST_API(params.user_id),
        method: "GET",
        params: request
      })
        .then(response => {
          resolve(response);
          if (params.page === 1) {
            commit("getOrderListNew", response.data);
          } else {
            commit("getOrderList", response.data);
          }
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  getOrderDetail({ commit }, ad_id) {
    return new Promise((resolve, reject) => {
      service({
        url: api.ORDER_DETAIL_API(ad_id),
        method: "GET"
      })
        .then(response => {
          resolve(response);
          commit("getOrderDetail", response.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  createOrder({ commit }, data) {
    return new Promise((resolve, reject) => {
      service({
        url: api.CREATE_ORDER_API(data.user_id),
        method: "POST",
        data
      })
        .then(response => {
          resolve(response);
          commit("saveOrder");
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  editOrder({ commit }, data) {
    return new Promise((resolve, reject) => {
      service({
        url: api.EDIT_ORDER_API(data.user_id, data.ad_id),
        method: "PUT",
        data
      })
        .then(response => {
          resolve(response);
          commit("saveOrder");
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  delayOrder(_, data) {
    return new Promise((resolve, reject) => {
      service({
        url: api.DELAY_ORDER_API,
        method: "POST",
        data: data
      })
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  delayOrderWomen(_, data) {
    return new Promise((resolve, reject) => {
      service({
        url: api.DELAY_ORDER_WOMEN_API,
        method: "POST",
        data: data
      })
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  cancelOrder(_, data) {
    return new Promise((resolve, reject) => {
      service({
        url: api.CANCEL_ORDER_API(data.user_id, data.ad_id),
        method: "PUT"
      })
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  designateOrder(_, data) {
    return new Promise((resolve, reject) => {
      service({
        url: api.DESIGNATE_ORDER_API(data.user_id),
        method: "POST",
        data: {
          ad_ids: data.ad_ids
        }
      })
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  getCastList({ commit }, ad_id) {
    return new Promise((resolve, reject) => {
      service({
        url: api.CAST_LIST_API(ad_id),
        method: "GET"
      })
        .then(response => {
          resolve(response);
          commit("getCastList", response.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  orderCast(_, data) {
    return new Promise((resolve, reject) => {
      service({
        url: api.ORDER_CAST_API(data.user_id, data.ad_id),
        method: "PUT",
        data: {
          user_ids: data.user_ids
        }
      })
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  resetOrder({ commit }) {
    commit("resetOrder");
  },
  reportOrder(_, data) {
    return new Promise((resolve, reject) => {
      service({
        url: api.REPORT_ORDER_API,
        method: "POST",
        data
      })
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  complainReportOrder(_, data) {
    return new Promise((resolve, reject) => {
      service({
        url: api.COMPLAIN_REPORT_ORDER_API,
        method: "POST",
        data
      })
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  contactReportOrder(_, data) {
    return new Promise((resolve, reject) => {
      service({
        url: api.CONTACT_REPORT_ORDER_API,
        method: "POST",
        data
      })
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  getUsersReportOrder(_, params) {
    return new Promise((resolve, reject) => {
      service({
        url: api.GET_USERS_REPORT_ORDER_API,
        method: "GET",
        params
      })
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  }
};
