import * as api from "@/api/api";
import service from "@/utils/axios";

export const state = {};

export const getters = {};

export const mutations = {};

export const actions = {
  // eslint-disable-next-line no-unused-vars
  rounglogRegister({ commit }, data) {
    return new Promise((resolve, reject) => {
      let params = data.params;
      service({
        url: api.ROUNDLOG_REGISTER + data.ad_id + "/roundlogs",
        method: "POST",
        params
      })
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  // eslint-disable-next-line no-unused-vars
  rounglogList({ commit }, params) {
    return new Promise((resolve, reject) => {
      service({
        url: api.ROUNDLOG_LIST,
        method: "GET",
        params
      })
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  }
};
