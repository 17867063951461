import { ValidationObserver, ValidationProvider } from "vee-validate";

export default {
  install(Vue) {
    Vue.component("ValidationObserver", ValidationObserver);
    Vue.component("ValidationProvider", ValidationProvider);
    Vue.component("AppContainer", () =>
      import("@/views/Common/AppContainer.vue")
    );
    Vue.component("ModalLayout", () =>
      import("@/views/Common/ModalLayout.vue")
    );
    Vue.component("AppLoading", () => import("@/views/Common/AppLoading.vue"));
    Vue.component("CardFooter", () => import("@/components/CardFooter.vue"));
    Vue.component("PointPdfExport", () => import("@/views/PointPdfExport.vue"));
  }
};
