export const REGISTER_EMAIL_API = "/api/v1/user/signup";

export const VERIFY_REGISTER_EMAIL_API = "/api/v1/user/active-account";

export const LOGIN_USER_API = "/api/v1/user/login";

export const URL_REDIRECT_FACEBOOK = "/api/v1/get-redirect-url-facebook";

export const URL_REDIRECT_LINE = "/api/v1/get-redirect-url-line";

export const FACEBOOK_CALLBACK = "/api/v1/auth/facebook/callback";

export const LINE_CALLBACK = "/api/v1/auth/line/callback";

export const EDIT_PROFILE = "/api/v1/user";

export const COMMON_TUTORIALS = "/api/v1/common-data";

export const GET_INFO_ACCOUNT = "/api/v1/users/me";

export const GET_NOTIFICATION_SETTING = "/api/v1/notification-settings";

export const UPDATE_NOTIFICATION_SETTING = "/api/v1/notification-settings";

export const GET_PG_POINT_HISTORY = "/api/v1/pgpoint-history";

export const GET_BD_POINT_HISTORY = "/api/v1/bdpoint-history";

export const GET_POINT_PACKAGE = "/api/v1/point-packages";

export const BUY_POINT_PACKAGE = "/api/v1/users/payment-stripe/checkout";

export const CHECK_PAYMENT_POINT_PACKAGE =
  "/api/v1/users/payment-stripe/checkout/";

// OFFER
export const GET_OFFER_URL = "/api/v1/common-tutorials";

export const GET_INFO_USER = "api/v1/users/";

export const ADS_API_URL = "/api/v1/ads";

export const UPDATE_PASSWORD = "/api/v1/users/change-password";

export const GET_NOTIFICATION_LIST = "api/v1/notifications";

export const CREATE_TOKEN_RESET_PASSWORD = "api/v1/password/create";

export const CHECK_TOKEN_PASSWORD = token => "api/v1/password/reset/" + token;

export const RESET_PASSWORD = "api/v1/password/reset";

export const UPLOAD_IMAGE_MESSAGE = "/api/v1/messages/upload-image";

export const SEND_EMAIL = "api/v1/messages/send-email";

export const UPLOAD_IMAGE_IDENTIFY = "/api/v1/users/identification/upload";

export const GET_TRACK = "/api/v1/footprint";

export const GET_NOT_READ_FOOTPRINT = "/api/v1/footprint/not-read";

export const GET_NOT_READ_ADS = "/api/v1/ads/not-read";

export const USER_URL = "/api/v1/users";

export const UNBLOCK_USER = "/api/v1/users/unblock";

export const UPDATE_BANK_ACCOUNT = "/api/v1/users/bank-account";

export const USER_UPDATE = "/api/v1/user/edit";

export const WITHDRAWAL_PG = "/api/v1/withdraw-pg";

export const WITHDRAWAL_BD = "/api/v1/withdraw-bd";

export const DETAILS_POINT = "/api/v1/payment-details/";

export const SUB_PUSH_NOTIFICATION = "/api/v1/push-notification/sub";

export const SEND_PUSH_NOTIFICATION__API = "api/v1/push-notification/send";

export const GET_AREAS = "api/v1/common-data/";

// Add card
export const GET_CARD = "/api/v1/users/payment-stripe/get-card";

export const ADD_CARD = "/api/v1/users/payment-stripe/add-card";

export const DELETE_CARD = "/api/v1/users/payment-stripe/delete-card";

export const GET_MONTH_PACKET =
  "/api/v1/users/payment-stripe/paymentMonthPacket";

export const ADD_MONTH_PACKET =
  "/api/v1/users/payment-stripe/addPaymentMonthPacket";

export const CANCEL_MONTH_PACKET =
  "/api/v1/users/payment-stripe/cancelPaymentPacket";

export const LIMIT_AREA = "/api/v1/users/check-order-limit";

// Round log
export const ROUNDLOG_REGISTER = "api/v1/ads/";
export const ROUNDLOG_LIST = "api/v1/roundlogs";

// user
export const DELETE_USER = "api/v1/users/delete-user";

export const LIST_AREA = "api/v1/users/get-area";

export const LIST_USER_SUGGEST = "api/v1/users/get-user-by-distance";

export const COUNT_USER_SUGGEST = "api/v1/users/count-user-suggest";

export const GET_PACKAGE_USE = "api/v1/users/get-package-use";

export const SAVE_MESSAGE_LOG = "api/v1/messages/send-message-log";

export const SEND_MESSAGE_LINE =
  "api/v1/messages/send-message-to-notified-by-official-line";
