import * as api from "@/api/api";
import service from "@/utils/axios";
import Cookie from "js-cookie";
export const state = {
  user: {}
};

export const getters = {
  user: state => state.user,
  token: state => state.access_token
};

export const mutations = {
  setTokenRegister(state, user) {
    state.token = user.token;
    Cookie.set("prego_token", user.token, {
      expires: user.expires_in / 86400
    });
  }
};

export const actions = {
  // eslint-disable-next-line no-unused-vars
  register({ commit }, data) {
    return new Promise((resolve, reject) => {
      service({
        url: api.REGISTER_EMAIL_API,
        method: "POST",
        data
      })
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  // eslint-disable-next-line no-unused-vars
  verifyRegister({ commit }, data) {
    return new Promise((resolve, reject) => {
      service({
        url: api.VERIFY_REGISTER_EMAIL_API,
        method: "POST",
        data
      })
        .then(response => {
          resolve(response);
          commit("setTokenRegister", response.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  //update bank account
  // eslint-disable-next-line no-unused-vars
  updateBankAccount({ commit }, data) {
    return new Promise((resolve, reject) => {
      service({
        url: api.UPDATE_BANK_ACCOUNT,
        method: "PUT",
        data
      })
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  }
};
