import * as api from "@/api/api";
import service from "@/utils/axios";

export const state = {
  cardInfo: null,
  monthlyPlanStatus: null
};

export const getters = {
  cardInfo: state => state.cardInfo,
  monthlyPlanStatus: state => state.monthlyPlanStatus
};

export const mutations = {
  setCardInfo(state, card) {
    state.cardInfo = card;
  },
  setMonthlyPlanStatus(state, status) {
    state.monthlyPlanStatus = status;
  }
};

export const actions = {
  // eslint-disable-next-line no-unused-vars
  getCreditCard({ commit }) {
    return new Promise((resolve, reject) => {
      service({
        url: api.GET_CARD,
        method: "GET"
      })
        .then(response => {
          resolve(response);
          commit("setCardInfo", response.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  // eslint-disable-next-line no-unused-vars
  addCreditCard({ commit }, data) {
    return new Promise((resolve, reject) => {
      service({
        url: api.ADD_CARD,
        method: "POST",
        data
      })
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  // eslint-disable-next-line no-unused-vars
  deleteCreditCard({ commit }) {
    return new Promise((resolve, reject) => {
      service({
        url: api.DELETE_CARD,
        method: "DELETE"
      })
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  // eslint-disable-next-line no-unused-vars
  getPaymentMonthPacket({ commit }) {
    return new Promise((resolve, reject) => {
      service({
        url: api.GET_MONTH_PACKET,
        method: "GET"
      })
        .then(response => {
          resolve(response);
          commit("setMonthlyPlanStatus", response.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  // eslint-disable-next-line no-unused-vars
  addPaymentMonthPacket({ commit }, data) {
    return new Promise((resolve, reject) => {
      service({
        url: api.ADD_MONTH_PACKET,
        method: "POST",
        data
      })
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  // eslint-disable-next-line no-unused-vars
  cancelPaymentPacket({ commit }, data) {
    return new Promise((resolve, reject) => {
      service({
        url: api.CANCEL_MONTH_PACKET,
        method: "POST",
        data
      })
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  // eslint-disable-next-line no-unused-vars
  cancelPaymentPacketDeleteUser({ commit }, data) {
    return new Promise((resolve, reject) => {
      service({
        url: api.CANCEL_MONTH_PACKET,
        method: "POST",
        data
      })
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  }
};
