import { extend } from "vee-validate";
import * as rules from "vee-validate/dist/rules";
import valid from "card-validator";

export async function loadVeeValidate(locale) {
  // if (locale == "ja") locale = "ja";
  const { messages } = await import(`vee-validate/dist/locale/${locale}.json`);
  Object.keys(rules).forEach(rule => {
    extend(rule, {
      ...rules[rule], // copies rule configuration
      message: messages[rule] // assign message
    });
  });
}

export async function loadVeeValidateExtend() {
  extend("min-option", {
    validate(value, quantityOption) {
      let arr = value.split(", ");
      if (arr.length < quantityOption) {
        return false;
      }
      return true;
    },
    message: "2ヶ所以上、選択してください。"
  });

  extend("option-min", {
    validate(value, quantityOption) {
      if (value.length < quantityOption) {
        return false;
      }
      return true;
    },
    message: "2ヶ所以上、選択してください。"
  });

  extend("ccn", {
    validate: value => valid.number(value).isValid,
    message: "無効なクレジットカード番号です。"
  });

  extend("cvc", {
    validate: value => valid.cvv(value).isValid || valid.cvv(value, 4).isValid,
    message: "CVCが不正です。"
  });
}
