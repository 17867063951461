import * as api from "@/api/api";
import service from "@/utils/axios";
import Cookie from "js-cookie";

export const state = {
  user: {},
  gender: null,
  access_token: Cookie.get("prego_token"),
  urlRedirectFacebook: null,
  urlRedirectLine: null,
  usersBlock: [],
  userIdsBlock: [],
  userIdsFavorite: [],
  tracks: [],
  usersRank: [],
  favorite: [],
  totalNotReadFootprint: 0,
  totalNotReadAds: {},
  listScores: [],
  adRoom: null,
  package: {},
  isShowToast: false
};

export const getters = {
  user: state => state.user,
  gender: state => state.gender,
  token: state => state.access_token,
  urlRedirectFacebook: state => state.urlRedirectFacebook,
  urlRedirectLine: state => state.urlRedirectLine,
  usersBlock: state => state.usersBlock,
  userIdsBlock: state => state.userIdsBlock,
  userIdsFavorite: state => state.userIdsFavorite,
  tracks: state => state.tracks,
  usersRank: state => state.usersRank,
  favorite: state => state.favorite,
  totalNotReadFootprint: state => state.totalNotReadFootprint,
  totalNotReadAds: state => state.totalNotReadAds,
  listScores: state => state.listScores,
  adRoom: state => state.adRoom,
  package: state => state.package,
  isShowToast: state => state.isShowToast
};

export const mutations = {
  setLogin(state, user) {
    state.token = user.token;
    Cookie.set("prego_token", user.token, {
      expires: user.expires_in / 86400
    });
  },
  setAccountInfo(state, user) {
    state.user = user;
  },
  setGenderUser(state, gender) {
    state.gender = gender;
  },
  setUrlRedirectFacebook(state, url) {
    state.urlRedirectFacebook = url;
  },
  setUrlRedirectLine(state, url) {
    state.urlRedirectLine = url;
  },
  // set users blocked
  SET_USERS_BLOCK(state, payload) {
    state.usersBlock = payload;
  },
  // set user_ids blocked
  SET_USER_IDS_BLOCK(state, payload) {
    state.userIdsBlock = payload;
  },
  // set user_ids favorited
  SET_USER_IDS_FAVORITE(state, payload) {
    state.userIdsFavorite = payload;
  },
  // set tracks
  SET_TRACK(state, payload) {
    state.tracks = payload;
  },

  // load more track
  SET_TRACK_LOAD_MORE(state, payload) {
    state.tracks = [...state.tracks, ...payload];
  },

  // update track is_block
  UPDATE_TRACK(state, payload) {
    let item = state.tracks.find(item => item.user_id === payload.user_id);
    item.is_block = 1;
  },

  // set total not read footprint
  SET_NOT_READ_FOOTPRINT(state, payload) {
    state.totalNotReadFootprint = payload;
  },

  // set total not read footprint
  SET_NOT_READ_ADS(state, payload) {
    state.totalNotReadAds = payload;
  },

  // set users rank
  SET_USERS_RANK(state, payload) {
    state.usersRank = payload;
  },

  // set favorite list
  SET_FAVORITE(state, payload) {
    state.favorite = payload;
  },

  // set favorite load more
  SET_FAVORITE_LOAD_MORE(state, payload) {
    state.favorite = [...state.favorite, ...payload];
  },

  // update favorite status
  UPDATE_FAVORITE(state, payload) {
    const item = state.favorite.find(item => item.user_id === payload.user_id);
    if (payload.success) {
      if (item.is_favorite === 1) {
        item.is_favorite = 0;
      } else {
        item.is_favorite = 1;
      }
    }
  },

  // set list scores timeline
  setListScore(state, payload) {
    state.listScores = payload;
  },

  // set ad room not rate
  setAdRoom(state, payload) {
    state.adRoom = payload;
  },

  // set
  setPackageUse(state, data) {
    state.package = data;
  },

  // set
  setShowToast(state, data) {
    state.isShowToast = data;
  }
};

export const actions = {
  setShowToast({ commit }, data) {
    commit("setShowToast", data);
  },
  loginUser({ commit }, data) {
    return new Promise((resolve, reject) => {
      service({
        url: api.LOGIN_USER_API,
        method: "POST",
        data
      })
        .then(response => {
          resolve(response);
          commit("setLogin", response.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  async getInfoUser({ commit }) {
    await service({
      url: api.GET_INFO_ACCOUNT,
      method: "GET"
    }).then(response => {
      commit("setAccountInfo", response.data);
    });
  },
  getUrlRedirectFacebook({ commit }) {
    service({
      url: api.URL_REDIRECT_FACEBOOK,
      method: "GET"
    }).then(response => {
      commit("setUrlRedirectFacebook", response);
    });
  },
  callbackFacebook({ commit }, params) {
    return new Promise((resolve, reject) => {
      service({
        url: api.FACEBOOK_CALLBACK,
        method: "POST",
        data: params
      })
        .then(response => {
          resolve(response);
          commit("setLogin", response.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  getUrlRedirectLine({ commit }, payload) {
    service({
      url: api.URL_REDIRECT_LINE,
      method: "GET",
      params: {
        sex: payload.sex,
        type: payload.type
      }
    }).then(response => {
      commit("setUrlRedirectLine", response);
    });
  },
  callbackLine({ commit }, params) {
    return new Promise((resolve, reject) => {
      service({
        url: api.LINE_CALLBACK,
        method: "POST",
        data: params
      })
        .then(response => {
          resolve(response);
          commit("setLogin", response.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  // get list user blocked
  async getUsersBlock({ commit }) {
    let data = [];
    await service({
      url: api.GET_INFO_USER + "block",
      method: "GET"
    })
      .then(response => (data = response))
      .catch(err => {
        return {
          data: {
            error: err
          }
        };
      });
    commit("SET_USERS_BLOCK", data);
  },
  // get list user_id blocked
  async getUserIdsFavorite({ commit }) {
    let data = [];
    await service({
      url: api.GET_INFO_USER + "favorite-ids",
      method: "GET"
    })
      .then(response => (data = response))
      .catch(err => {
        return {
          data: {
            error: err
          }
        };
      });
    commit("SET_USER_IDS_FAVORITE", data);
  },
  // get list user_id favorited
  async getUserIdsBlock({ commit }) {
    let data = [];
    await service({
      url: api.GET_INFO_USER + "block-ids",
      method: "GET"
    })
      .then(response => (data = response))
      .catch(err => {
        return {
          data: {
            error: err
          }
        };
      });
    commit("SET_USER_IDS_BLOCK", data);
  },
  // get list tracks
  async getTrackList({ commit }, params) {
    let commitType = params.commit_type; // SET_TRACK; SET_TRACK_LOAD_MORE
    delete params.commit_type;
    return new Promise((resolve, reject) => {
      service({
        url: api.GET_TRACK,
        method: "GET",
        params
      })
        .then(response => {
          resolve(response);
          commit(commitType, response.data.users);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  // get total not read footprint
  async getTotalNotReadFootprint({ commit }) {
    return new Promise((resolve, reject) => {
      service({
        url: api.GET_NOT_READ_FOOTPRINT,
        method: "GET"
      })
        .then(response => {
          resolve(response);
          commit("SET_NOT_READ_FOOTPRINT", response.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  async getTotalNotReadAds({ commit }) {
    return new Promise((resolve, reject) => {
      service({
        url: api.GET_NOT_READ_ADS,
        method: "GET"
      })
        .then(response => {
          resolve(response);
          commit("SET_NOT_READ_ADS", response.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  // update track
  async updateTrack({ commit }, data) {
    commit("UPDATE_TRACK", data);
  },

  // unblock
  // eslint-disable-next-line no-unused-vars
  unblock({ commit }, data) {
    return new Promise((resolve, reject) => {
      service({
        url: api.UNBLOCK_USER,
        method: "DELETE",
        data
      })
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  // get users rank
  getUsersRank({ commit }, params) {
    return new Promise((resolve, reject) => {
      service({
        url: api.USER_URL + "/rankings",
        method: "GET",
        params
      })
        .then(response => {
          resolve(response);
          commit("SET_USERS_RANK", response.data.users);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  // get list favorite
  getFavoriteList({ commit }, params) {
    let commitType = params.commit_type;
    delete params.commit_type;
    return new Promise((resolve, reject) => {
      service({
        url: api.USER_URL + "/favorites",
        method: "GET",
        params
      })
        .then(response => {
          resolve(response);
          commit(commitType, response.data.users);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  // update state favorite
  updateFavorite({ commit }, data) {
    commit("UPDATE_FAVORITE", data);
  },

  // get list score for timeline
  async getListScores({ commit }) {
    return new Promise((resolve, reject) => {
      service({
        url: api.GET_INFO_USER + "scores",
        method: "GET"
      })
        .then(response => {
          resolve(response);
          commit("setListScore", response.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  // get ad room not rate
  async getAdNotRate({ commit }) {
    return new Promise((resolve, reject) => {
      service({
        url: api.ADS_API_URL + "/not-rate",
        method: "GET"
      })
        .then(response => {
          resolve(response);
          commit("setAdRoom", response.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  // get users package
  async getUsersPackage({ commit }) {
    return new Promise((resolve, reject) => {
      service({
        url: api.GET_PACKAGE_USE,
        method: "GET"
      })
        .then(response => {
          resolve(response);
          commit("setPackageUse", response.data.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  sendMessageToNotifiedByLine(_, data) {
    return new Promise((resolve, reject) => {
      service({
        url: api.SEND_MESSAGE_LINE,
        method: "POST",
        data
      })
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  }
};
