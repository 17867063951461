import * as api from "@/api/api";
import service from "@/utils/axios";

export const state = {
  limit_area: null,
  number_order: null
};

export const getters = {
  limit_area: state => state.limit_area,
  number_order: state => state.number_order
};

export const mutations = {
  setLimit(state, limit_area) {
    state.limit_area = limit_area;
  },
  setNumberOrder(state, number_order) {
    state.number_order = number_order;
  }
};

export const actions = {
  async getLimitArea({ commit }) {
    await service({
      url: api.LIMIT_AREA,
      method: "GET"
    }).then(response => {
      commit("setLimit", response["check-limit-order"]);
      commit("setNumberOrder", response["number-order"]);
    });
  }
};
