import Cookies from "js-cookie";
import store from "../../store";
import {
  GENERAL_PASSWORD,
  FACEBOOK_DOMAIN,
  LINE_DOMAIN
} from "@/constants/message";
import firebase from "firebase";

export default async function auth({ to, next }) {
  const token = Cookies.get("prego_token");
  if (to.matched.some(record => record.meta.requiresVisitor)) {
    if (!token) {
      next({ name: "TopRegister" });
    } else if (token) {
      await store.dispatch("auth/getInfoUser");
      const user = store.getters["auth/user"];
      const date = new Date();
      const dateNow =
        date.getFullYear() + "/" + date.getMonth() + "/" + date.getDate();
      if (to.name != "Home" && to.name != "CastHome") {
        localStorage.setItem("showUserSuggest", true);
      }
      if (
        !localStorage.getItem("step") &&
        localStorage.getItem("round_log_" + user.user_id) != dateNow
      ) {
        await store.dispatch("auth/getAdNotRate");
        const adRoom = store.getters["auth/adRoom"];
        if (adRoom) {
          localStorage.setItem("round_log_" + user.user_id, dateNow);
          localStorage.setItem("ad_room_" + user.user_id, adRoom);
          if (user.sex === 1) {
            next({ name: "Home" });
          } else {
            next({ name: "CastHome" });
          }
        }
      }
      if (
        to.name !== "MenCreateOrderStepOne" &&
        to.name !== "MenCreateOrderDirectStepOne"
      ) {
        localStorage.removeItem("createOrder");
      }
      const storageTooltip = localStorage.getItem(
        "tooltip_user_" + user.user_id
      );
      if (storageTooltip != 0 && storageTooltip != 1) {
        // const arrOrder = [];
        // localStorage.setItem(localStorageName, JSON.stringify(arrOrder));
        const localStorageName = "tooltip_user_" + user.user_id;
        localStorage.setItem(localStorageName, 0);
      }
      const firebaseUser = firebase.auth().currentUser;
      if (!firebaseUser) {
        const md5 = require("md5");
        let email = "";
        switch (user.login_type) {
          case "EMAIL":
            email = user.email;
            break;

          case "FACEBOOK":
            email = user.facebook_id + FACEBOOK_DOMAIN;
            break;

          case "LINE":
            email = user.line_id + LINE_DOMAIN;
            break;
        }
        const data = {
          email: email,
          password: md5(GENERAL_PASSWORD)
        };
        firebase.auth().signInWithEmailAndPassword(data.email, data.password);
      }
      const gender = user.sex;
      if (!gender) {
        localStorage.removeItem("step");
        if (to.name !== "RegisterProfile") next({ name: "RegisterProfile" });
      } else if (user.registration_status == 1) {
        if (
          to.name !== "RegisterProfile" &&
          to.name !== "MenTermOfUse" &&
          to.name !== "CastTermOfUse" &&
          to.name !== "MenPolicy" &&
          to.name !== "CastPolicy"
        )
          next({ name: "RegisterProfile" });
      } else {
        let genderUrl = "CastMyPage";
        if (gender === 1) {
          genderUrl = "MenMyPage";
        }
        const routeProfile = ["MenMyPage", "CastMyPage"];
        if (routeProfile.includes(to.name) && !user.post_code) {
          localStorage.setItem("isNewProfile", 1);
        } else if (user.post_code) {
          localStorage.removeItem("isNewProfile");
        }
        if (
          localStorage.getItem("isNewProfile") &&
          !user.post_code &&
          !to.path.includes("my-page")
        ) {
          if (gender === 1) {
            store.dispatch("auth/setShowToast", true);
            next({ name: "MenMyPage" });
          } else {
            next({ name: "CastMyPage" });
          }
        } else {
          if (
            to.meta.gender == gender ||
            ROUTE_COMMON.includes(to.name) ||
            to.meta.gender === 0
          ) {
            if (to.name === genderUrl + "Withdrawal") {
              if (!user.bank_name) {
                next({ name: "403" });
              } else if (!localStorage.getItem("point")) {
                if (gender == 1) {
                  next({ name: "Home" });
                } else {
                  next({ name: "CastHome" });
                }
              } else {
                next();
              }
            } else if (
              to.name !== genderUrl + "BankAccountRegistration" &&
              to.name !== genderUrl + "WithdrawalPG"
            ) {
              // create bank account
              localStorage.removeItem("point");
            }
            if (to.name === "CastMyPagePointHistory") {
              // cast point history
              if (user.user_type !== 2) {
                next({ name: "403" });
              }
            } else {
              next();
            }
          } else if (to.name === "Home") {
            if (gender === 2) {
              next({ name: "CastHome" });
            }
          } else {
            next({ name: "403" });
          }
        }
      }
    }
    next();
  } else {
    if (
      (to.name === "LoginEmail" ||
        to.name === "Register" ||
        to.name === "EmailRegister") &&
      token
    ) {
      await store.dispatch("auth/getInfoUser");
      const user = store.getters["auth/user"];
      if (user.sex === 2) {
        next({ name: "CastHome" });
      } else {
        next({ name: "Home" });
      }
    }
    next();
  }
}
const ROUTE_COMMON = [
  "Law",
  "Notification",
  "Help",
  "ChangePassword",
  "ResetPasswordByEmail",
  "Block",
  "IdentifyProfile",
  "BankAccountRegistration",
  "RoundLog",
  "MyPage-EditProfile",
  "PersonalInformation",
  "IdentifyProfileStepTwo",
  "IdentifyProfileStepThree"
];
