<template>
  <div id="app">
    <Loading ref="loading" />
    <router-view />
  </div>
</template>

<style lang="scss">
@import "./assets/scss/base.scss";

#app {
  font-family: Robinson Regular;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #000000;
  user-select: none;
}
@font-face {
  font-family: "Robinson Regular";
  src: url("assets/fonts/robinson-regular.otf") format("truetype");
}
@font-face {
  font-family: "Hiragino Kaku Gothic Pro W3";
  src: url("assets/fonts/hiragino-kaku-gothic-pro-w3.otf") format("truetype");
}
// @font-face {
//   font-family: "Hiragino Kaku Gothic Pro W3"; // change font w3
//   font-weight: bold;
//   src: url("assets/fonts/hiragino-kaku-gothic-pro-w6.otf") format("truetype");
// }
@font-face {
  font-family: "abeatbyKai,Regular";
  src: url("assets/fonts/ABEAKRG.TTF") format("truetype");
}
@font-face {
  font-family: "Avenir Next, Bold";
  src: url("assets/fonts/AvenirNext-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "ITC Bookman, Light Italic";
  src: url("assets/fonts/Bookman-LightItalic.otf") format("truetype");
}

@font-face {
  font-family: "HGMaruGothicMPRO";
  src: url("assets/fonts/HGMaruGothicMPRO.ttf") format("truetype");
}

@font-face {
  font-family: "Segoe UI, Bold";
  src: url("assets/fonts/SegoeUIBold.ttf") format("truetype");
}

@font-face {
  font-family: "Segoe UI, Regular";
  src: url("assets/fonts/SegoeUI.ttf") format("truetype");
}
</style>

<script>
import Loading from "./components/Loading";
import { mapGetters } from "vuex";
import firebase from "firebase";
import Cookies from "js-cookie";
export default {
  el: "#app",
  components: {
    Loading
  },
  computed: {
    ...mapGetters({
      user: "auth/user"
    })
  },

  created() {
    if (window.performance) {
      localStorage.removeItem("showUserSuggest");
    }
    // do something based on the navigation type...
    if (performance.navigation.type === 1) {
      localStorage.setItem("showUserSuggest", true);
    }
  },
  watch: {
    user: function(newVal, oldVal) {
      if (this.user.is_archived == 1) {
        Cookies.remove("prego_token");
        firebase.auth().signOut();
        this.$router.push({ name: "TopRegister" });
      }
      if (newVal.user_id && newVal.user_id !== oldVal.user_id) {
        this.getDeviceToken();
      }
    }
  },
  methods: {
    getDeviceToken: function() {
      if (this.$messaging) {
        this.$messaging
          .getToken({
            vapidKey: process.env.VUE_APP_FIREBASE_VAPID_KEY
          })
          .then(currentToken => {
            if (currentToken) {
              this.$store.dispatch("userApp/subPushNotification", {
                device_token: currentToken
              });
            }
          });
      }
    }
  }
};
</script>
