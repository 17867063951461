const data = {
  users: {
    user_id: "12345",
    user_type: "1",
    nickname: "タナカタナカ",
    last_name_kanji: "タナカ",
    first_name_kanji: "タナカ",
    last_name_kana: "タナカ",
    first_name_kana: "タナカ",
    sex: "2",
    identity_verification_status: "0",
    email: "sample@sample.com",
    login_type: "EMAIL",
    points_balance: "1,000pt",
    frozen_points: "1,000pt",
    is_founder: "0",
    is_interviewed: "0",
    over_rank_score: "GOLD",
    memo:
      "メモです。メモです。メモです。メモです。メモです。メモです。 メモです。メモです。メモです。メモです。メモです。メモです。 メモです。メモです。メモです。メモです。メモです。メモです。 メモです。メモです。メモです。メモです。メモです。メモです。 メモです。メモです。メモです。メモです。メモです。メモです。 メモです。メモです。メモです。メモです。メモです。メモです。 メモです。メモです。メモです。メモです。メモです。メモです。 メモです。メモです。メモです。メモです。メモです。メモです。 メモです。メモです。メモです。メモです。メモです。メモです。",
    date_of_birth: "1990年4月4日",
    residence_id: "県埼玉",
    occupation_id: "弁護士",
    height: "193 cm",
    relationship_id: "既婚",
    best_score: "99",
    avg_score: "99",
    golf_experience_id: "10年以上",
    golf_frequency_id: "10",
    cigarette_id: "吸わない",
    alcohol_id: "飲む",
    annual_income_id: "3,000 万円",
    has_golf_membership: "0",
    figure_id: "ぽっちゃり",
    car_model: "ベンツ",
    appearance_id: "綺麗系",
    meeting_method: "1",
    attitude_id: "楽しくゴルフ",
    biography:
      "自己紹介です。自己紹介です。自己紹介です。自己紹介です。自己紹介です。自己紹介です。自己紹介です。自己紹介です。自己紹介です。自己紹介です。自己紹介です。自己紹介です。自己紹介です。自己紹介です。自己紹介です。自己紹介です。自己紹介です。自己紹介です。自己紹介です。自己紹介です。自己紹介です。自己紹介です。自己紹介です。自己紹介です。自己紹介です。自己紹介です。自己紹介です。自己紹介です。",
    thirty_minutes_pay: "30,000 円",
    thirty_transportation_costs: "30,000 円",
    designation_fee: "30,000 円",
    last_login: "01/01/2021 0:00:00",
    invitation_code: "https://sample.com",
    bank_name: "三菱UFJ銀行",
    branch_name: "東京支店",
    account_type: "1",
    account_number: "1234567",
    account_name: "タナカショウジ（カ"
  },
  golf_areas: { name: "埼玉県、茨城県、山梨県" },
  male_golf_ranks: { name: "GOLD" },
  female_golf_ranks: { name: "GOLD" },
  male_prego_ranks: { name: "GOLD" },
  female_prego_ranks: { name: "GOLD" }
};

export const state = {
  deleteUser: null,
  listUsers: [
    {
      user_id: "12345",
      user_type: "一般",
      nickname: "タナカタナカ",
      last_name_kanji: "田中",
      first_name_kanji: "田中",
      sex: "男性",
      login_type: "Eメール",
      email: "sample@sample.com",
      points_balance: "1,000 pt",
      total_points_consumed: "1,000 pt",
      identity_verification_status: "確認済み",
      inviting_user_id: "12345",
      total_number_of_users: "80",
      best_score: "80",
      avg_score: "80",
      golf_experience_id: "10年以上",
      last_login: "01/02/2021 0:00:00"
    },
    {
      user_id: "12345",
      user_type: "一般",
      nickname: "タナカタナカ",
      last_name_kanji: "田中",
      first_name_kanji: "田中",
      sex: "男性",
      login_type: "Eメール",
      email: "sample@sample.com",
      points_balance: "1,000 pt",
      total_points_consumed: "1,000 pt",
      identity_verification_status: "確認済み",
      inviting_user_id: "12345",
      total_number_of_users: "80",
      best_score: "80",
      avg_score: "80",
      golf_experience_id: "10年以上",
      last_login: "01/02/2021 0:00:00"
    },
    {
      user_id: "12345",
      user_type: "一般",
      nickname: "タナカタナカ",
      last_name_kanji: "田中",
      first_name_kanji: "田中",
      sex: "男性",
      login_type: "Eメール",
      email: "sample@sample.com",
      points_balance: "1,000 pt",
      total_points_consumed: "1,000 pt",
      identity_verification_status: "確認済み",
      inviting_user_id: "12345",
      total_number_of_users: "80",
      best_score: "80",
      avg_score: "80",
      golf_experience_id: "10年以上",
      last_login: "01/02/2021 0:00:00"
    },
    {
      user_id: "12345",
      user_type: "一般",
      nickname: "タナカタナカ",
      last_name_kanji: "田中",
      first_name_kanji: "田中",
      sex: "男性",
      login_type: "Eメール",
      email: "sample@sample.com",
      points_balance: "1,000 pt",
      total_points_consumed: "1,000 pt",
      identity_verification_status: "確認済み",
      inviting_user_id: "12345",
      total_number_of_users: "80",
      best_score: "80",
      avg_score: "80",
      golf_experience_id: "10年以上",
      last_login: "01/02/2021 0:00:00"
    },
    {
      user_id: "12345",
      user_type: "一般",
      nickname: "タナカタナカ",
      last_name_kanji: "田中",
      first_name_kanji: "田中",
      sex: "男性",
      login_type: "Eメール",
      email: "sample@sample.com",
      points_balance: "1,000 pt",
      total_points_consumed: "1,000 pt",
      identity_verification_status: "確認済み",
      inviting_user_id: "12345",
      total_number_of_users: "80",
      best_score: "80",
      avg_score: "80",
      golf_experience_id: "10年以上",
      last_login: "01/02/2021 0:00:00"
    },
    {
      user_id: "12345",
      user_type: "一般",
      nickname: "タナカタナカ",
      last_name_kanji: "田中",
      first_name_kanji: "田中",
      sex: "男性",
      login_type: "Eメール",
      email: "sample@sample.com",
      points_balance: "1,000 pt",
      total_points_consumed: "1,000 pt",
      identity_verification_status: "確認済み",
      inviting_user_id: "12345",
      total_number_of_users: "80",
      best_score: "80",
      avg_score: "80",
      golf_experience_id: "10年以上",
      last_login: "01/02/2021 0:00:00"
    },
    {
      user_id: "12345",
      user_type: "一般",
      nickname: "タナカタナカ",
      last_name_kanji: "田中",
      first_name_kanji: "田中",
      sex: "男性",
      login_type: "Eメール",
      email: "sample@sample.com",
      points_balance: "1,000 pt",
      total_points_consumed: "1,000 pt",
      identity_verification_status: "確認済み",
      inviting_user_id: "12345",
      total_number_of_users: "80",
      best_score: "80",
      avg_score: "80",
      golf_experience_id: "10年以上",
      last_login: "01/02/2021 0:00:00"
    }
  ],
  userInfo: {
    users: {
      user_id: "12345",
      user_type: "1",
      nickname: "タナカタナカ",
      last_name_kanji: "タナカ",
      first_name_kanji: "タナカ",
      last_name_kana: "タナカ",
      first_name_kana: "タナカ",
      sex: "2",
      identity_verification_status: "0",
      email: "sample@sample.com",
      login_type: "EMAIL",
      points_balance: "1,000pt",
      frozen_points: "1,000pt",
      is_founder: "0",
      is_interviewed: "0",
      over_rank_score: "GOLD",
      memo:
        "メモです。メモです。メモです。メモです。メモです。メモです。 メモです。メモです。メモです。メモです。メモです。メモです。 メモです。メモです。メモです。メモです。メモです。メモです。 メモです。メモです。メモです。メモです。メモです。メモです。 メモです。メモです。メモです。メモです。メモです。メモです。 メモです。メモです。メモです。メモです。メモです。メモです。 メモです。メモです。メモです。メモです。メモです。メモです。 メモです。メモです。メモです。メモです。メモです。メモです。 メモです。メモです。メモです。メモです。メモです。メモです。",
      date_of_birth: "1990年4月4日",
      residence_id: "県埼玉",
      occupation_id: "弁護士",
      height: "193 cm",
      relationship_id: "既婚",
      best_score: "99",
      avg_score: "99",
      golf_experience_id: "10年以上",
      golf_frequency_id: "10",
      cigarette_id: "吸わない",
      alcohol_id: "飲む",
      annual_income_id: "3,000 万円",
      has_golf_membership: "0",
      figure_id: "ぽっちゃり",
      car_model: "ベンツ",
      appearance_id: "綺麗系",
      meeting_method: "1",
      attitude_id: "楽しくゴルフ",
      biography:
        "自己紹介です。自己紹介です。自己紹介です。自己紹介です。自己紹介です。自己紹介です。自己紹介です。自己紹介です。自己紹介です。自己紹介です。自己紹介です。自己紹介です。自己紹介です。自己紹介です。自己紹介です。自己紹介です。自己紹介です。自己紹介です。自己紹介です。自己紹介です。自己紹介です。自己紹介です。自己紹介です。自己紹介です。自己紹介です。自己紹介です。自己紹介です。自己紹介です。",
      thirty_minutes_pay: "30,000 円",
      thirty_transportation_costs: "30,000 円",
      designation_fee: "30,000 円",
      last_login: "01/01/2021 0:00:00",
      invitation_code: "https://sample.com",
      bank_name: "三菱UFJ銀行",
      branch_name: "東京支店",
      account_type: "1",
      account_number: "1234567",
      account_name: "タナカショウジ（カ"
    },
    golf_areas: { name: "埼玉県、茨城県、山梨県" },
    male_golf_ranks: { name: "GOLD" },
    female_golf_ranks: { name: "GOLD" },
    male_prego_ranks: { name: "GOLD" },
    female_prego_ranks: { name: "GOLD" }
  }
};

export const getters = {
  listUsers: state => state.listUsers,
  deleteUser: state => state.deleteUser,
  userInfo: state => state.userInfo
};

export const mutations = {
  setListUsers(state, listUsers) {
    state.listUsers = listUsers;
  },
  setDeleteUser(state, deleteUser) {
    state.deleteUser = deleteUser;
  },
  setInfoUser(state, userInfo) {
    state.userInfo = userInfo;
  }
};

export const actions = {
  getUsers({ commit }, params) {
    console.log(params);
    commit("setListUsers", state.listUsers);
    return state.listUsers;
  },
  deleteUser({ commit }, params) {
    console.log(params);
    commit("setDeleteUser", params);
  },
  infoUser({ commit }, params) {
    console.log(params);
    commit("setInfoUser", state.userInfo);
    return state.userInfo;
  },
  initUserInfo({ commit }, params) {
    // TODO get info user API
    if (params === 2) {
      console.log("@3232323");
      commit("setInfoUser", {});
    } else {
      commit("setInfoUser", data);
    }
  }
};
