import * as api from "@/api/api";
import service from "@/utils/axios";

export const state = {
  user: {},
  listUsers: {},
  areas: {},
  image_url: null,
  listUsersSuggest: []
};

export const getters = {
  user: state => state.user,
  listUser: state => state.listUser,
  areas: state => state.areas,
  image_url: state => state.image_url,
  listUsersSuggest: state => state.listUsersSuggest
};

export const mutations = {
  setUser(state, user) {
    state.user = user;
  },
  setListUsers(state, listUsers) {
    state.listUsers = listUsers;
  },
  setAreas(state, areas) {
    state.areas = areas;
  },
  SET_LIST_USER(state, payload) {
    state.listUsers = payload;
  },
  SET_LIST_USER_MORE(state, payload) {
    state.listUsers = [...state.listUsers, ...payload];
  },

  getListUsersSuggest(state, data) {
    state.listUsersSuggest = state.listUsersSuggest.concat(data);
  }
};

export const actions = {
  getInfoUser({ commit }, id) {
    return new Promise((resolve, reject) => {
      service({
        url: api.GET_INFO_USER + id,
        method: "GET"
      })
        .then(response => {
          resolve(response);
          commit("setUser", response.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  getListUsers({ commit }, params) {
    return new Promise((resolve, reject) => {
      service({
        url: api.USER_URL,
        method: "GET",
        params
      })
        .then(response => {
          resolve(response);
          commit("setListUsers", response.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  getListUser2({ commit }, params) {
    let commitType = params.commit_type; // SET_LIST_USER; SET_LIST_USER_MORE
    delete params.commit_type;
    return new Promise((resolve, reject) => {
      service({
        url: api.USER_URL,
        method: "GET",
        params
      })
        .then(response => {
          resolve(response);
          commit(commitType, response.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  subPushNotification(_, params) {
    return new Promise((resolve, reject) => {
      service({
        url: api.SUB_PUSH_NOTIFICATION,
        method: "POST",
        params
      })
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  getAreas({ commit }, id) {
    return new Promise((resolve, reject) => {
      service({
        url: api.GET_AREAS + id + "/areas",
        method: "GET"
      })
        .then(response => {
          resolve(response);
          commit("setAreas", response.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  deleteUser() {
    return new Promise((resolve, reject) => {
      service({
        url: api.DELETE_USER,
        method: "DELETE"
      })
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  getListSuggestUsers({ commit }, params) {
    return new Promise((resolve, reject) => {
      service({
        url: api.LIST_USER_SUGGEST,
        method: "GET",
        params
      })
        .then(response => {
          resolve(response);
          commit("getListUsersSuggest", response.data.data.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  getNumberSuggestUsers() {
    return new Promise((resolve, reject) => {
      service({
        url: api.COUNT_USER_SUGGEST,
        method: "GET"
      })
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  }
};
