import * as api from "@/api/api";
import service from "@/utils/axios";

export const state = {
  point_packages: {}
};

export const getters = {
  point_packages: state => state.point_packages
};

export const mutations = {
  setPointPackages(state, data) {
    state.point_packages = data;
  }
};

export const actions = {
  getPointPackages({ commit }) {
    return new Promise((resolve, reject) => {
      service({
        url: api.GET_POINT_PACKAGE,
        method: "GET"
      })
        .then(response => {
          resolve(response);
          commit("setPointPackages", response.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  }
};
