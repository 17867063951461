import firebase from "../../firebase";
import service from "@/utils/axios";
import * as api from "@/api/api";
import { GROUP_ROOM_TYPE } from "@/constants/message";
// import Vue from "vue";
import router from "@/router";
import fb from "firebase";
// import { MESSAGES_TYPE } from "@/utils/const";

const db = firebase.db;

export const state = {
  listRoom: [],
  room: {},
  messages: [],
  roomId: null,
  ads: {},
  applicant: {},
  listTweet: [],
  unread: [],
  owner: null,
  members: [],
  profileTweets: [],
  listTweetsProfile: [],
  typeTweet: null
};

export const getters = {
  listRoom: state => state.listRoom,
  room: state => state.room,
  messages: state => state.messages,
  roomId: state => state.roomId,
  ads: state => state.ads,
  applicant: state => state.applicant,
  listTweet: state => state.listTweet,
  unread: state => state.unread,
  owner: state => state.owner,
  members: state => state.members,
  profileTweets: state => state.profileTweets,
  listTweetsProfile: state => state.listTweetsProfile,
  typeTweet: state => state.typeTweet
};

export const mutations = {
  setListRoom(state, data) {
    state.listRoom = data;
  },
  setRoom(state, room) {
    state.room = room;
  },
  setMembers(state, members) {
    state.members = members;
  },
  setOwner(state, owner) {
    state.owner = owner;
  },
  setListMessage(state, messages) {
    state.messages = messages;
  },
  setRoomId(state, roomId) {
    state.roomId = roomId;
  },
  SET_ADS(state, payload) {
    state.ads = payload;
  },
  SET_APPLICANT(state, payload) {
    state.applicant = payload;
  },
  setListTweet(state, tweets) {
    state.listTweet = tweets;
  },
  setUnread(state, unread) {
    state.unread = unread;
  },
  setProfileTweets(state, tweets) {
    state.profileTweets = tweets;
  },
  setListProfileTweets(state, tweets) {
    state.listTweetsProfile = tweets;
  },

  setTypeTweet(state, type) {
    state.typeTweet = type;
  }
};

export const actions = {
  // eslint-disable-next-line no-unused-vars
  async updateUser({ commit }, data) {
    await db
      .collection("users")
      .doc(data.userId)
      .update(data.field);
  },

  // eslint-disable-next-line no-unused-vars
  async createRoom({ commit }, data) {
    // check if not group room && room exist
    if (data.type !== GROUP_ROOM_TYPE) {
      let rooms = await db
        .collection("rooms")
        .where("type", "==", data.type)
        .where("hiddenUserIds", "==", data.hiddenUserIds)
        .get();
      if (rooms.docs.length > 0) {
        return rooms.docs[0].id;
      }
    }

    // if group room || room not exist => create new room
    let room = await db.collection("rooms").add({
      adId: data.adId,
      type: data.type,
      userIds: data.userIds,
      hiddenUserIds: data.hiddenUserIds,
      lastSentDateTime: new Date(),
      createdAt: new Date()
    });

    return room.id;
  },

  // eslint-disable-next-line no-unused-vars
  async getListRoom({ commit }, data) {
    let users = [];
    if (!data.lastRoom) {
      state.listRoom = [];
      db.collection("rooms")
        .where("type", "in", data.type)
        .where("userIds", "array-contains", data.userId)
        .orderBy("lastSentDateTime", "desc")
        .limit(10)
        .onSnapshot(async rooms => {
          // let listRoom = [...state.listRoom];
          for (const room of rooms.docChanges()) {
            if (room.type == "added") {
              Object.keys(room.doc.data().hiddenUserIds).map(userId => {
                if (!users[userId]) {
                  users[userId] = db
                    .collection("users")
                    .doc(userId)
                    .get();
                }
              });
            }
          }
          users = await Promise.all(users);

          rooms.docChanges().forEach(async room => {
            if (room.type == "added") {
              let owner = null,
                members = [],
                roomName = [];
              Object.keys(room.doc.data().hiddenUserIds).forEach(userId => {
                if (room.doc.data().type === 3) {
                  if (users[userId].data().sex === 1) {
                    owner = users[userId].data();
                    roomName.unshift(users[userId].data().nickname);
                  } else {
                    members.push(users[userId].data());
                    roomName.push(users[userId].data().nickname);
                  }
                } else {
                  if (parseInt(userId) === data.userId) {
                    owner = users[userId].data();
                  } else {
                    members.push(users[userId].data());
                  }
                }
              });
              let notSeenMessage = await db
                .collection("rooms")
                .doc(room.doc.id)
                .collection("messages")
                .where("readUsers." + data.userId, "==", false)
                .get()
                .then(querySnapshot => {
                  return querySnapshot.size;
                });
              let notSeenMessageHidden = await db
                .collection("rooms")
                .doc(room.doc.id)
                .collection("messages")
                .where("readUsers." + data.userId, "==", false)
                .where("type", "in", [11])
                .get()
                .then(querySnapshot => {
                  return querySnapshot.size;
                });
              let lastMessage = await db
                .collection("rooms")
                .doc(room.doc.id)
                .collection("messages")
                .orderBy("createdAt", "desc")
                .limit(10)
                .get();
              lastMessage = lastMessage.docs.find(message => {
                return (
                  message.data().readUsers[data.userId] === false ||
                  message.data().readUsers[data.userId] === true
                );
              });
              const roomIndex = state.listRoom.findIndex(
                roomInfo => room.doc.id === roomInfo.id
              );
              if (roomIndex < 0) {
                console.log("add", room.newIndex, room.doc.data());
                state.listRoom.splice(room.newIndex, 0, {
                  ...room.doc.data(),
                  id: room.doc.id,
                  message: lastMessage ? lastMessage.data() : "",
                  notify: notSeenMessage - notSeenMessageHidden,
                  owner: owner,
                  members: members,
                  roomName: roomName
                });
              }
            } else {
              if (room.type == "modified") {
                await db
                  .collection("rooms")
                  .doc(room.doc.id)
                  .collection("messages")
                  .orderBy("createdAt", "desc")
                  .limit(10)
                  .get()
                  .then(querySnapshot => {
                    const rs = querySnapshot.docs.find(message => {
                      return (
                        message.data().readUsers[data.userId] === false ||
                        message.data().readUsers[data.userId] === true
                      );
                    });
                    return rs.data();
                  })
                  .then(async lastMessage => {
                    let notSeenMessages = await db
                      .collection("rooms")
                      .doc(room.doc.id)
                      .collection("messages")
                      .where("readUsers." + data.userId, "==", false)
                      .get()
                      .then(querySnapshot => {
                        return querySnapshot.size;
                      });
                    let notSeenMessageHidden = await db
                      .collection("rooms")
                      .doc(room.doc.id)
                      .collection("messages")
                      .where("readUsers." + data.userId, "==", false)
                      .where("type", "in", [11])
                      .get()
                      .then(querySnapshot => {
                        return querySnapshot.size;
                      });
                    const roomIndex = state.listRoom.findIndex(
                      roomInfo => room.doc.id === roomInfo.id
                    );
                    const roomData = Object.assign(
                      {},
                      state.listRoom[roomIndex],
                      {
                        lastSentDateTime: room.doc.data().lastSentDateTime,
                        message: lastMessage,
                        notify: notSeenMessages - notSeenMessageHidden
                      }
                    );
                    state.listRoom.splice(roomIndex, 1);
                    state.listRoom.splice(room.newIndex, 0, roomData);
                  });
              }
              if (room.type == "removed") {
                const roomIndex = state.listRoom.findIndex(
                  roomInfo => room.doc.id === roomInfo.id
                );
                state.listRoom.splice(roomIndex, 1);
              }
            }
          });
          // commit("setListRoom", listRoom);
        });
    } else {
      let lastRoom = await db
        .collection("rooms")
        .doc(data.lastRoom)
        .get();
      db.collection("rooms")
        .where("type", "in", data.type)
        .where("userIds", "array-contains", data.userId)
        .orderBy("lastSentDateTime", "desc")
        .startAfter(lastRoom)
        .limit(10)
        .onSnapshot(async rooms => {
          let listRoom = [...state.listRoom];
          for (const room of rooms.docChanges()) {
            if (room.type == "added") {
              Object.keys(room.doc.data().hiddenUserIds).map(userId => {
                if (!users[userId]) {
                  users[userId] = db
                    .collection("users")
                    .doc(userId)
                    .get();
                }
              });
            }
          }
          users = await Promise.all(users);

          rooms.docChanges().forEach(async room => {
            if (room.type == "added") {
              let owner = null,
                members = [],
                roomName = [];
              Object.keys(room.doc.data().hiddenUserIds).forEach(userId => {
                if (room.doc.data().type === 3) {
                  if (users[userId].data().sex === 1) {
                    owner = users[userId].data();
                    roomName.unshift(users[userId].data().nickname);
                  } else {
                    members.push(users[userId].data());
                    roomName.push(users[userId].data().nickname);
                  }
                } else {
                  if (userId === data.userId) {
                    owner = users[userId].data();
                  } else {
                    members.push(users[userId].data());
                  }
                }
              });
              let notSeenMessage = await db
                .collection("rooms")
                .doc(room.doc.id)
                .collection("messages")
                .where("readUsers." + data.userId, "==", false)
                .get();
              let notSeenMessageHidden = await db
                .collection("rooms")
                .doc(room.doc.id)
                .collection("messages")
                .where("readUsers." + data.userId, "==", false)
                .where("type", "in", [11])
                .get();
              let lastMessage = await db
                .collection("rooms")
                .doc(room.doc.id)
                .collection("messages")
                .orderBy("createdAt", "desc")
                .limit(10)
                .get();
              if (lastMessage.docs.length > 0) {
                lastMessage = lastMessage.docs.find(message => {
                  return (
                    message.data().readUsers[data.userId] === false ||
                    message.data().readUsers[data.userId] === true
                  );
                });
              } else {
                lastMessage = null;
              }
              state.listRoom.push({
                ...room.doc.data(),
                id: room.doc.id,
                message: lastMessage ? lastMessage.data() : "",
                notify:
                  notSeenMessage.docs.length - notSeenMessageHidden.docs.length,
                owner: owner,
                members: members,
                roomName: roomName
              });
            } else {
              const roomIndex = listRoom.findIndex(
                roomInfo => room.doc.id === roomInfo.id
              );
              if (room.type == "modified") {
                let notSeenMessage = await db
                  .collection("rooms")
                  .doc(room.doc.id)
                  .collection("messages")
                  .where("readUsers." + data.userId, "==", false)
                  .get();
                let notSeenMessageHidden = await db
                  .collection("rooms")
                  .doc(room.doc.id)
                  .collection("messages")
                  .where("readUsers." + data.userId, "==", false)
                  .where("type", "in", [11])
                  .get();
                let lastMessage = await db
                  .collection("rooms")
                  .doc(room.doc.id)
                  .collection("messages")
                  .orderBy("createdAt", "desc")
                  .limit(10)
                  .get();
                if (lastMessage.docs.length > 0) {
                  lastMessage = lastMessage.docs.find(message => {
                    return (
                      message.data().readUsers[data.userId] === false ||
                      message.data().readUsers[data.userId] === true
                    );
                  });
                }
                const roomData = Object.assign({}, listRoom[roomIndex], {
                  lastSentDateTime: room.doc.data().lastSentDateTime,
                  message: lastMessage ? lastMessage.data() : "",
                  notify:
                    notSeenMessage.docs.length -
                    notSeenMessageHidden.docs.length
                });
                if (notSeenMessage.size - notSeenMessageHidden.size > 0) {
                  state.listRoom.splice(roomIndex, 1);
                  state.listRoom.splice(room.newIndex, 1, roomData);
                }
              }
              if (room.type == "removed") {
                state.listRoom.splice(roomIndex, 1);
              }
            }
          });
          // commit("setListRoom", listRoom);
        });
    }
    return state.listRoom;
  },

  getUnread({ commit }, data) {
    state.unread = [];
    db.collection("rooms")
      .where("userIds", "array-contains", data.userId)
      .orderBy("lastSentDateTime", "desc")
      .onSnapshot(async rooms => {
        let listNotify = [...state.unread];
        rooms.docChanges().forEach(async room => {
          if (room.type === "added") {
            let notRead = await db
              .collection("rooms")
              .doc(room.doc.id)
              .collection("messages")
              .where("readUsers." + data.userId, "==", false)
              .get();
            let notSeenMessageHidden = await db
              .collection("rooms")
              .doc(room.doc.id)
              .collection("messages")
              .where("readUsers." + data.userId, "==", false)
              .where("type", "in", [11])
              .get();
            listNotify.splice(room.newIndex, 0, {
              roomId: room.doc.id,
              notify: notRead.docs.length - notSeenMessageHidden.docs.length
            });
            if (notRead.size > 0) {
              commit("setUnread", listNotify);
            }
          }
          if (room.type === "modified") {
            const roomIndex = listNotify.findIndex(
              roomInfo => room.doc.id === roomInfo.id
            );
            let notRead = await db
              .collection("rooms")
              .doc(room.doc.id)
              .collection("messages")
              .where("readUsers." + data.userId, "==", false)
              .get();
            let notSeenMessageHidden = await db
              .collection("rooms")
              .doc(room.doc.id)
              .collection("messages")
              .where("readUsers." + data.userId, "==", false)
              .where("type", "in", [11])
              .get();
            listNotify.splice(roomIndex, 1);
            listNotify.splice(room.newIndex, 0, {
              roomId: room.doc.id,
              notify: notRead.docs.length - notSeenMessageHidden.docs.length
            });
            if (notRead.size > 0) {
              commit("setUnread", listNotify);
            }
          }
        });
      });
  },

  async getRoom({ commit }, data) {
    db.collection("rooms")
      .doc(data.roomId)
      .get()
      .then(async room => {
        commit("setRoom", { ...room.data(), id: room.id });

        let users = {},
          members = [],
          owner = null;
        for (const userId of Object.keys(room.data().hiddenUserIds)) {
          let userTemp = await db
            .collection("users")
            .doc(userId)
            .get();
          users[userId] = { ...userTemp.data(), id: userTemp.id };
        }
        let systemUser = await db
          .collection("users")
          .doc("0")
          .get();
        users[0] = { ...systemUser.data(), id: systemUser.id };
        if (!data.lastMessage) {
          state.messages = [];
          // get ads by room to display button pick-up / drop off
          let adId = room.data().adId;
          if (adId) {
            service({
              url: api.ADS_API_URL + "/" + adId,
              method: "GET"
            }).then(response => {
              commit("SET_ADS", response.data);
            });
            service({
              url: api.ADS_API_URL + "/" + adId + "/user/" + data.userId,
              method: "GET"
            }).then(response => {
              commit("SET_APPLICANT", response.data);
            });
          } else {
            commit("SET_ADS", {});
          }

          Object.keys(room.data().hiddenUserIds).forEach(userId => {
            if (room.data().type === 3) {
              if (users[userId].sex === 1) {
                owner = users[userId];
              } else {
                if (userId === data.userId) {
                  members.unshift(users[userId]);
                } else {
                  members.push(users[userId]);
                }
              }
            } else {
              if (parseInt(userId) === data.userId) {
                owner = users[userId];
              } else {
                members.push(users[userId]);
              }
            }
          });
          commit("setMembers", members);
          commit("setOwner", owner);

          db.collection("rooms")
            .doc(data.roomId)
            .collection("messages")
            .orderBy("createdAt", "desc")
            .limit(20)
            .onSnapshot(messages => {
              let listMessage = [...state.messages];
              messages.docChanges().forEach(mes => {
                if (
                  mes.type === "added" &&
                  data.roomId === state.roomId &&
                  (mes.doc.data().readUsers[data.userId] === false ||
                    mes.doc.data().readUsers[data.userId] === true)
                ) {
                  if (mes.doc.data().readUsers[data.userId] === false) {
                    db.collection("rooms")
                      .doc(data.roomId)
                      .collection("messages")
                      .doc(mes.doc.id)
                      .update({
                        ["readUsers." + data.userId]: true
                      });
                  }
                  if (
                    !listMessage[mes.newIndex] ||
                    (listMessage[mes.newIndex] &&
                      listMessage[mes.newIndex].id !== mes.doc.id)
                  ) {
                    listMessage.splice(mes.newIndex, 0, {
                      ...mes.doc.data(),
                      id: mes.doc.id,
                      user:
                        mes.doc.data().userId && users[mes.doc.data().userId.id]
                          ? users[mes.doc.data().userId.id]
                          : null,
                      roomId: data.roomId
                    });
                  }
                }
                let roomIndex = state.listRoom.findIndex(
                  room => room.id === state.roomId
                );
                if (state.listRoom[roomIndex]) {
                  const roomData = Object.assign(
                    {},
                    state.listRoom[roomIndex],
                    {
                      notify: 0
                    }
                  );
                  state.listRoom.splice(roomIndex, 1, roomData);
                }
                let unreadIndex = state.unread.findIndex(
                  unread => unread.roomId === state.roomId
                );
                if (state.unread[unreadIndex]) {
                  const unreadData = Object.assign(
                    {},
                    state.unread[unreadIndex],
                    {
                      notify: 0
                    }
                  );
                  state.unread.splice(unreadIndex, 1, unreadData);
                }
              });
              commit("setListMessage", listMessage);
            });
        } else {
          let lastMessage = await db
            .collection("rooms")
            .doc(data.roomId)
            .collection("messages")
            .doc(data.lastMessage)
            .get();
          db.collection("rooms")
            .doc(data.roomId)
            .collection("messages")
            .orderBy("createdAt", "desc")
            .startAfter(lastMessage)
            .limit(20)
            .onSnapshot(messages => {
              let listMessage = [...state.messages];
              messages.docChanges().forEach(mes => {
                if (mes.type === "added" && data.roomId === state.roomId) {
                  if (mes.doc.data().readUsers[data.userId] === false) {
                    db.collection("rooms")
                      .doc(data.roomId)
                      .collection("messages")
                      .doc(mes.doc.id)
                      .update({
                        ["readUsers." + data.userId]: true
                      });
                  }

                  listMessage.push({
                    ...mes.doc.data(),
                    id: mes.doc.id,
                    user: users[mes.doc.data().userId.id]
                      ? users[mes.doc.data().userId.id]
                      : null,
                    roomId: data.roomId
                  });
                }
              });
              commit("setListMessage", listMessage);
            });
        }
      })
      .catch(error => {
        router.push({ name: "Messages" });
        throw error;
      });
  },

  // eslint-disable-next-line no-unused-vars
  // async getListRoomSystem({ commit }, userIds) {
  //   userIds = userIds.map(async userId => {
  //     const hiddenUserIds = {
  //       [0]: true,
  //       [userId["user_id"]]: true
  //     };
  //     let room = await db
  //       .collection("rooms")
  //       .where("type", "==", SYSTEM_ROOM_TYPE)
  //       .where("hiddenUserIds", "==", hiddenUserIds)
  //       .get();
  //     if (room.docs.length > 0) {
  //       return {
  //         read_users: {
  //           [0]: true,
  //           [userId["user_id"]]: false
  //         },
  //         user_id: userId["user_id"],
  //         to_user_id: userId["to_user_id"],
  //         room_id: room.docs[0].id
  //       };
  //     } else {
  //       room = await db.collection("rooms").add({
  //         adId: null,
  //         type: SYSTEM_ROOM_TYPE,
  //         userIds: [0, parseInt(userId["user_id"])],
  //         hiddenUserIds: hiddenUserIds,
  //         lastSentDateTime: new Date(),
  //         createdAt: new Date()
  //       });
  //       return {
  //         read_users: {
  //           [0]: true,
  //           [userId["user_id"]]: false
  //         },
  //         to_user_id: userId["to_user_id"],
  //         user_id: userId["user_id"],
  //         room_id: room.id
  //       };
  //     }
  //   });
  //   userIds = await Promise.all(userIds);

  //   return userIds;
  // },

  resetListMessage({ commit }) {
    commit("setListMessage", []);
  },

  // eslint-disable-next-line no-unused-vars
  async createMessage({ commit }, data) {
    let userId = data.message.userId;
    if (userId) {
      data.message.userId = db.doc("/users/" + userId);
    }
    let room = db.collection("rooms").doc(data.roomId);
    room.update({
      lastSentDateTime: new Date()
    });
    room.collection("messages").add(data.message);
    let roomInfo = await room.get();
    Object.keys(roomInfo.data().hiddenUserIds).forEach(userId => {
      room.update({
        userIds: fb.firestore.FieldValue.arrayUnion(parseInt(userId))
      });
    });
  },

  // eslint-disable-next-line no-unused-vars
  async deleteMessageCalendar({ commit }, data) {
    let room = db.collection("rooms").doc(data.roomId);
    room
      .collection("messages")
      .doc(data.calendarMsgId)
      .delete();
  },

  // eslint-disable-next-line no-unused-vars
  createSystemMessage({ commit, dispatch }, data) {
    let messageContent =
      `Superオファーがお相手に届きました。<br>
      お相手が内容を見てエントリーするまで待ちましょう。<br>
      進捗はマイページの「Superオファー履歴」から確認できます。<br>
      「Superオファー」に数の制限はないので他の女性にも送ることが可能です。<br>
      ※オファーした内容を変更したい場合は「Superオファー履歴」の詳細から変更お願いします。` +
      `<br><a class="a-box" href="` +
      data.message.url +
      `">詳細はこちら</a>`;
    // `<br>日付: ` +
    // data.message.scheduled_meeting_date +
    // `<br>時間: ` +
    // data.message.scheduled_meeting_time +
    // `<br>プラン: ` +
    // data.message.plan +
    // `<br>人数: ` +
    // data.message.participants +
    // `<br>ゴルフ場： ` +
    // data.message.golf_course_name +
    let dataMessage = {
      roomId: data.roomId,
      message: {
        createdAt: new Date(),
        giftId: null,
        imageUrl: "",
        message: messageContent,
        type: 1,
        userId: "0",
        readUsers: data.readUsers
      }
    };
    dispatch("createMessage", dataMessage);
  },

  // eslint-disable-next-line no-unused-vars
  async uploadImage({ commit }, data) {
    return new Promise((resolve, reject) => {
      service({
        url: api.UPLOAD_IMAGE_MESSAGE,
        method: "POST",
        data
      })
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  // eslint-disable-next-line no-unused-vars
  async sendEmail({ commit }, data) {
    return new Promise((resolve, reject) => {
      service({
        url: api.SEND_EMAIL,
        method: "POST",
        data
      })
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  // eslint-disable-next-line no-unused-vars
  hideRoom({ commit }, data) {
    db.collection("rooms")
      .doc(data.roomId)
      .update({
        userIds: fb.firestore.FieldValue.arrayRemove(data.userId)
      });
  },

  // eslint-disable-next-line no-unused-vars
  updateRoomSendContinuePopup({ commit }, data) {
    db.collection("rooms")
      .doc(data.roomId)
      .update({
        sendContinuePopup: data.value
      });
  },

  // eslint-disable-next-line no-unused-vars
  updateRoomCheckDisplayPopup({ commit }, data) {
    db.collection("rooms")
      .doc(data.roomId)
      .update({
        checkDisplayPopup: data.value
      });
  },

  setRoomId({ commit }, roomId) {
    commit("setRoomId", roomId);
  },

  sendPushNotification(_, data) {
    return new Promise((resolve, reject) => {
      service({
        url: api.SEND_PUSH_NOTIFICATION__API,
        method: "POST",
        data
      })
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  // eslint-disable-next-line no-unused-vars
  async getListTweet({ commit }, data) {
    let listUser = await db
      .collection("users")
      .where("sex", "==", data.sex)
      .get();
    let users = listUser.docs.reduce((listUser, user) => {
      listUser[user.id] = user.data();

      return listUser;
    }, {});
    let authUser = await db
      .collection("users")
      .doc(data.userId.toString())
      .get();
    users[data.userId] = authUser.data();
    // let tweetCollection = db.collection("tweets");
    // if (data.type === 2) {
    //   tweetCollection = db
    //     .collection("tweets")
    //     .where("likes", "array-contains", data.userId);
    // }
    if (!data.lastTweet) {
      state.listTweet = [];
      db.collection("tweets")
        .orderBy("createdAt", "desc")
        .onSnapshot(tweets => {
          let listTweet = [...state.listTweet];
          let number = 0;
          tweets.docChanges().forEach(tweet => {
            if (
              (!data.listIdsBlock.includes(
                parseInt(tweet.doc.data().userId.id)
              ) &&
                !tweet.doc.data().hiddenUserIds.includes(data.userId) &&
                data.type === state.typeTweet &&
                users[tweet.doc.data().userId.id] &&
                number < 10 &&
                data.type === 1) ||
              (!data.listIdsBlock.includes(
                parseInt(tweet.doc.data().userId.id)
              ) &&
                !tweet.doc.data().hiddenUserIds.includes(data.userId) &&
                data.type === state.typeTweet &&
                users[tweet.doc.data().userId.id] &&
                data.listIdsFavorite.includes(
                  parseInt(tweet.doc.data().userId.id)
                ) &&
                number < 10 &&
                data.type === 2)
            ) {
              const tweetIndex = listTweet.findIndex(
                tweetInfo => tweet.doc.id === tweetInfo.id
              );
              if (tweet.type == "added" && tweetIndex < 0) {
                listTweet.splice(number, 0, {
                  ...tweet.doc.data(),
                  id: tweet.doc.id,
                  user: users[tweet.doc.data().userId.id]
                });
                number++;
              }
              if (tweet.type == "modified" && tweetIndex >= 0) {
                const tweetData = Object.assign({}, listTweet[tweetIndex], {
                  message: tweet.doc.data().message,
                  imageUrls: tweet.doc.data().imageUrls,
                  hiddenUserIds: tweet.doc.data().hiddenUserIds,
                  likes: tweet.doc.data().likes
                });
                listTweet.splice(tweetIndex, 1, tweetData);
              }
              if (tweet.type == "removed" && tweetIndex >= 0) {
                listTweet.splice(tweetIndex, 1);
              }
            }
          });
          commit("setListTweet", listTweet);
        });
    } else {
      let lastTweet = await db
        .collection("tweets")
        .doc(data.lastTweet)
        .get();
      db.collection("tweets")
        .orderBy("createdAt", "desc")
        .startAfter(lastTweet)
        .onSnapshot(tweets => {
          let listTweet = [...state.listTweet];
          let number = 0;
          tweets.docChanges().forEach(tweet => {
            if (
              (!data.listIdsBlock.includes(
                parseInt(tweet.doc.data().userId.id)
              ) &&
                !data.listIdsBlock.includes(tweet.doc.data().userId.id) &&
                data.type === state.typeTweet &&
                users[tweet.doc.data().userId.id] &&
                number < 10 &&
                data.type === 1) ||
              (!data.listIdsBlock.includes(
                parseInt(tweet.doc.data().userId.id)
              ) &&
                !tweet.doc.data().hiddenUserIds.includes(data.userId) &&
                data.type === state.typeTweet &&
                users[tweet.doc.data().userId.id] &&
                data.listIdsFavorite.includes(
                  parseInt(tweet.doc.data().userId.id)
                ) &&
                number < 10 &&
                data.type === 2)
            ) {
              const tweetIndex = listTweet.findIndex(
                tweetInfo => tweet.doc.id === tweetInfo.id
              );
              if (tweet.type == "added" && tweetIndex < 0) {
                listTweet.push({
                  ...tweet.doc.data(),
                  id: tweet.doc.id,
                  user: users[tweet.doc.data().userId.id]
                });
                number++;
              }
              if (tweet.type == "modified" && tweetIndex >= 0) {
                const tweetData = Object.assign({}, listTweet[tweetIndex], {
                  message: tweet.doc.data().message,
                  imageUrls: tweet.doc.data().imageUrls,
                  hiddenUserIds: tweet.doc.data().hiddenUserIds,
                  likes: tweet.doc.data().likes
                });
                listTweet.splice(tweetIndex, 1, tweetData);
              }
              if (tweet.type == "removed" && tweetIndex >= 0) {
                listTweet.splice(tweetIndex, 1);
              }
            }
          });
          commit("setListTweet", listTweet);
        });
    }
  },

  hiddenUserBlocked({ commit }, userId) {
    let listTweet = state.listTweet.filter(tweet => tweet.userId.id !== userId);
    commit("setListTweet", listTweet);
  },

  resetListTweet({ commit }) {
    commit("setListTweet", []);
    commit("setListProfileTweets", []);
  },

  setTypeTweet({ commit }, type) {
    commit("setTypeTweet", type);
  },

  // eslint-disable-next-line no-unused-vars
  getProfileTweets({ commit }, data) {
    db.collection("tweets")
      .where("userId", "==", db.collection("users").doc(data.userId.toString()))
      .orderBy("createdAt", "desc")
      .limit(3)
      .get()
      .then(async tweets => {
        let listTweet = [];
        let user = await db
          .collection("users")
          .doc(data.userId.toString())
          .get();
        tweets.forEach(tweet => {
          listTweet.push({
            ...tweet.data(),
            id: tweet.id,
            user: user.data()
          });
        });
        commit("setProfileTweets", listTweet);
      });
  },

  async getListProfileTweets({ commit }, data) {
    let listTweet = [];
    if (data.like) {
      db.collection("tweets")
        .where(
          "userId",
          "==",
          db.collection("users").doc(data.userId.toString())
        )
        .orderBy("createdAt", "desc")
        .limit(state.listTweetsProfile.length + 10)
        .get()
        .then(async tweets => {
          let user = await db
            .collection("users")
            .doc(data.userId.toString())
            .get();
          tweets.forEach(tweet => {
            listTweet.push({
              ...tweet.data(),
              id: tweet.id,
              user: user.data()
            });
          });
          commit("setListProfileTweets", listTweet);
        });
    } else if (!data.lastTweet) {
      db.collection("tweets")
        .where(
          "userId",
          "==",
          db.collection("users").doc(data.userId.toString())
        )
        .orderBy("createdAt", "desc")
        .limit(10)
        .get()
        .then(async tweets => {
          listTweet = [...state.listTweet];
          let user = await db
            .collection("users")
            .doc(data.userId.toString())
            .get();
          tweets.forEach(tweet => {
            listTweet.push({
              ...tweet.data(),
              id: tweet.id,
              user: user.data()
            });
          });
          commit("setListProfileTweets", listTweet);
        });
    } else {
      listTweet = [...state.listTweetsProfile];
      let lastTweet = await db
        .collection("tweets")
        .doc(data.lastTweet)
        .get();
      db.collection("tweets")
        .where(
          "userId",
          "==",
          db.collection("users").doc(data.userId.toString())
        )
        .orderBy("createdAt", "desc")
        .startAfter(lastTweet)
        .limit(10)
        .get()
        .then(async tweets => {
          let user = await db
            .collection("users")
            .doc(data.userId.toString())
            .get();
          tweets.forEach(tweet => {
            listTweet.push({
              ...tweet.data(),
              id: tweet.id,
              user: user.data()
            });
          });
          commit("setListProfileTweets", listTweet);
        });
    }
  },

  // eslint-disable-next-line no-unused-vars
  createTweet({ commit }, data) {
    db.collection("tweets").add({
      userId: db.collection("users").doc(data.userId),
      message: data.message,
      imageUrls: data.imageUrls,
      createdAt: new Date(),
      hiddenUserIds: [],
      likes: []
    });
  },

  // eslint-disable-next-line no-unused-vars
  editTweet({ commit }, data) {
    db.collection("tweets")
      .doc(data.tweetId)
      .update({
        message: data.message,
        imageUrls: data.imageUrls
      });
  },

  // eslint-disable-next-line no-unused-vars
  likeTweet({ commit }, data) {
    switch (data.action) {
      case "like":
        db.collection("tweets")
          .doc(data.tweetId)
          .update({
            likes: fb.firestore.FieldValue.arrayUnion(data.userId)
          });
        break;
      case "unlike":
        db.collection("tweets")
          .doc(data.tweetId)
          .update({
            likes: fb.firestore.FieldValue.arrayRemove(data.userId)
          });
        break;
    }
  },

  // eslint-disable-next-line no-unused-vars
  hideTweet({ commit }, data) {
    db.collection("tweets")
      .doc(data.tweetId)
      .update({
        hiddenUserIds: fb.firestore.FieldValue.arrayUnion(data.userId)
      });
  },

  // eslint-disable-next-line no-unused-vars
  deleteTweet({ commit }, tweetId) {
    db.collection("tweets")
      .doc(tweetId)
      .delete();
  },
  updateSubmitMessage(_, data) {
    db.collection("rooms")
      .doc(data.roomId)
      .collection("messages")
      .doc(data.messageId)
      .update({
        isSubmit: true
      });
  },

  // eslint-disable-next-line no-unused-vars
  async saveMessageLogs({ commit }, data) {
    return new Promise((resolve, reject) => {
      service({
        url: api.SAVE_MESSAGE_LOG,
        method: "POST",
        data
      })
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  }
};
