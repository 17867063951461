import * as api from "@/api/api";
import service from "@/utils/axios";

export const state = {};

export const getters = {};

export const mutations = {};

export const actions = {
  getNotificationSetting() {
    return new Promise((resolve, reject) => {
      service({
        url: api.GET_NOTIFICATION_SETTING,
        method: "GET"
      })
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  // eslint-disable-next-line no-unused-vars
  updateNotificationSetting({ commit }, data) {
    return new Promise((resolve, reject) => {
      service({
        url: api.UPDATE_NOTIFICATION_SETTING,
        method: "PUT",
        data
      })
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  // eslint-disable-next-line no-unused-vars
  getNotificationList({ commit }, params) {
    return new Promise((resolve, reject) => {
      service({
        url: api.GET_NOTIFICATION_LIST,
        method: "GET",
        params
      })
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  }
};
