import * as api from "@/api/api";
import service from "@/utils/axios";

export const state = {
  user_pg_points: {},
  user_bd_points: {},
  detailsPoint: {},
  dataExport: {}
};

export const getters = {
  user_pg_points: state => state.user_pg_points,
  user_bd_points: state => state.user_bd_points,
  detailsPoint: state => state.detailsPoint,
  dataExport: state => state.dataExport
};

export const mutations = {
  setUserPgPoint(state, data) {
    state.user_pg_points = data;
  },
  setUserBdPoint(state, data) {
    state.user_bd_points = data;
  },
  setDetailsPoint(state, data) {
    state.detailsPoint = data;
  }
};

export const actions = {
  getListPointHistory({ commit }, params) {
    return new Promise((resolve, reject) => {
      service({
        url: api.GET_PG_POINT_HISTORY,
        method: "GET",
        params: {
          page: params.page,
          perpage: params.perpage,
          type: params.type
        }
      })
        .then(response => {
          resolve(response);
          commit("setUserPgPoint", response.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  getListBdPoint({ commit }, params) {
    return new Promise((resolve, reject) => {
      service({
        url: api.GET_BD_POINT_HISTORY,
        method: "GET",
        params: {
          page: params.page,
          perpage: params.perpage,
          type: params.type
        }
      })
        .then(response => {
          resolve(response);
          commit("setUserBdPoint", response.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  // eslint-disable-next-line no-unused-vars
  withDrawal({ commit }, data) {
    return new Promise((resolve, reject) => {
      service({
        url: api.WITHDRAWAL_PG,
        method: "POST",
        data
      })
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  // eslint-disable-next-line no-unused-vars
  withDrawalBd({ commit }, data) {
    return new Promise((resolve, reject) => {
      service({
        url: api.WITHDRAWAL_BD,
        method: "POST",
        data
      })
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  // eslint-disable-next-line no-unused-vars
  getDetailsPoint({ commit }, params) {
    return new Promise((resolve, reject) => {
      service({
        url: api.DETAILS_POINT + params,
        method: "GET"
      })
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  }
};
